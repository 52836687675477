import React, { FunctionComponent } from 'react';
import { Box } from '@chakra-ui/react';
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer } from '@chakra-ui/react';
import { DashboardCustomerDeviceInfo } from '../../generated/types';

interface Props {
    data: DashboardCustomerDeviceInfo[];
}

export const CBDevices: FunctionComponent<Props> = (props) => {
    const devices = props.data;
    return (
        <>
            <Box>Geräte</Box>
            <TableContainer mb={5}>
                <Table variant={'striped'}>
                    <Thead>
                        <Tr>
                            <Th>Model</Th>
                            <Th>OS</Th>
                            <Th>OS Version</Th>
                            <Th>App Version</Th>
                            <Th>Locale</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {devices?.map((device, i) => (
                            <Tr key={i}>
                                <Td>{device.deviceModel}</Td>
                                <Td>{device.os}</Td>
                                <Td>{device.osVersion}</Td> <Td>{device.appVersion}</Td>
                                <Td>{device.locale}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    );
};
