import React, { FunctionComponent } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Reading } from './TableContainer';
import { Text } from '../Text';

interface Props {
    data: Reading[];
}

export const StateReading: FunctionComponent<Props> = (props) => {
    const { data } = props;
    return (
        <Flex>
            {data.map((item) => (
                <Flex key={`${item.color}_${item.text}`} flex={1}>
                    <Box pl="6px" bg={item.color} />
                    <Text pl={2}>{item.text}</Text>
                </Flex>
            ))}
        </Flex>
    );
};
