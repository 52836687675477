import React, { FunctionComponent } from 'react';
import { Box } from '@chakra-ui/react';
import { Spacer as SpacerProps } from '../content_builder_inferface/spacer';

interface Props {
    spacer: SpacerProps;
}

export const Spacer: FunctionComponent<Props> = (props) => {
    const { spacer } = props;
    return <Box height={spacer.height} />;
};
