import React, { FunctionComponent } from 'react';
import { Link, ListItem, UnorderedList } from '@chakra-ui/react';
import { Attachment, useDashboardAttachmentForCommunicationThreadAttachmentMutation } from '../../generated/types';
import { formatBytes, base64toBlob } from '../../helper/files';

export interface Props {
    data: Attachment[];
}

export const CBAttachmentList: FunctionComponent<Props> = (props) => {
    const { data } = props;

    const [, executeGetAttachment] = useDashboardAttachmentForCommunicationThreadAttachmentMutation();

    const getAttachment = async (attachmentId: string, filename: string) => {
        const result = await executeGetAttachment({ communicationThreadAttachmentId: attachmentId });
        const attachment = result.data?.dashboardAttachmentForCommunicationThreadAttachment;

        if (attachment && attachment.dataBase64 && attachment.mimeType) {
            const blob = base64toBlob(attachment.dataBase64, attachment.mimeType);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            a.click();
        }
    };

    return (
        <UnorderedList>
            {data.map((item: Attachment) => {
                return (
                    <ListItem key={item.attachmentId}>
                        <Link onClick={() => getAttachment(item.attachmentId, item.fileName)}>
                            {item.fileName} ({formatBytes(item.fileSize)})
                        </Link>
                    </ListItem>
                );
            })}
        </UnorderedList>
    );
};
