import { Config } from '../content_builder_inferface';
import { IoPerson, IoMail, IoPersonOutline, IoPersonAddOutline } from 'react-icons/io5';

export const config: Config = {
    stats: [
        {
            as: 'CBStatBox',
            data: 'invitedCustomers',
            statBox: {
                title: 'invitedCustomers',
                icon: IoPersonAddOutline,
            },
        },
        {
            as: 'CBStatBox',
            data: 'acceptedCustomers',
            statBox: {
                title: 'appAcceptedCustomers',
                icon: IoPersonOutline,
            },
        },
        {
            as: 'CBStatBox',
            data: 'appinuseCustomers',
            statBox: {
                title: 'activeCustomers',
                icon: IoPerson,
                link: '/app-customers',
            },
        },
        {
            as: 'CBStatBox',
            data: 'unreadCommunications',
            statBox: {
                title: 'openMessages',
                icon: IoMail,
                link: '/communication',
            },
        },
        {
            as: 'CBStatBox',
            data: 'autoInviteInvitedCustomers',
            statBox: {
                title: 'autoInviteInvitedCustomers',
            },
        },
        {
            as: 'CBStatBox',
            data: 'autoInviteInstalledCustomers',
            statBox: {
                title: 'autoInviteInstalledCustomers',
            },
        },
        {
            as: 'CBStatBox',
            data: 'autoInviteSignedCustomers',
            statBox: {
                title: 'autoInviteSignedCustomers',
            },
        },
        {
            as: 'CBStatBox',
            data: 'autoInviteAppInUseCustomers',
            statBox: {
                title: 'autoInviteAppInUseCustomers',
            },
        },
    ],
};
