import React, { FunctionComponent } from 'react';
import { Box, Stack } from '@chakra-ui/react';
import { TableMultilineText } from '../../content_builder_inferface/tableMultilineText';

interface Props {
    data: string;
    tableMultilineText: TableMultilineText;
}

export const CBTableMultilineText: FunctionComponent<Props> = (props) => {
    const { data, tableMultilineText } = props;

    return (
        <Stack spacing={1}>
            {data.split(tableMultilineText.indicator).map((item, index) => (
                <Box key={index}>{item}</Box>
            ))}
        </Stack>
    );
};
