import React, { FunctionComponent, useState, ChangeEvent, useEffect } from 'react';
import { Stack, HStack, FormControl, InputGroup, Select, Input, InputLeftElement } from '@chakra-ui/react';
import { IoSearchOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

export interface SearchSelectOption {
    text: string;
    color?: string;
    value?: string;
}

interface Props {
    placeholder: string;
    setGlobalFilter: (filterValue: string) => void;
    setFilter: (columnId: string, updater: string, filter?: string) => void;
    selectConfig?: {
        columnAccessor: string;
        selectOptions: SearchSelectOption[];
    };
    startSearchValue?: string;
}

export const Search: FunctionComponent<Props> = (props: Props) => {
    const [searchValue, setSearchValue] = useState('');
    const [selectValue, setSelectValue] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        if (props.startSearchValue) {
            setNewFilter(props.startSearchValue);
        }
    }, [props.startSearchValue]);

    const setNewFilter = (value: string) => {
        setSearchValue(value);
        props.setGlobalFilter(value);
    };

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value || '';
        setNewFilter(value);
    };

    const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        setSelectValue(value);
        props.setFilter(props.selectConfig?.columnAccessor || '', value);
    };

    return (
        <Stack spacing="4" direction={{ base: 'column', md: 'row' }} justify="space-between" py="6">
            <HStack>
                <FormControl minW={{ sm: '280px', md: '520px' }} id="search">
                    <InputGroup>
                        <InputLeftElement color="color6" pointerEvents="none">
                            <IoSearchOutline />
                        </InputLeftElement>
                        <Input
                            bg="color2"
                            placeholder={props.placeholder}
                            onChange={handleSearchChange}
                            value={searchValue}
                        />
                    </InputGroup>
                </FormControl>
                {props.selectConfig && props.selectConfig.selectOptions && (
                    <Select rounded="base" value={selectValue} onChange={handleSelectChange}>
                        <option value="">{t('All')}</option>
                        {props.selectConfig.selectOptions.map((item) => (
                            <option value={item.value ?? item.text} key={item.text}>
                                {t(item.text)}
                            </option>
                        ))}
                    </Select>
                )}
            </HStack>
        </Stack>
    );
};
