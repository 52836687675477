import { Text } from './Text';
import { CBTab } from './content_builder_components/CBTab';
import { CBTable } from './content_builder_components/CBTable';
import { CBProfileCard } from './content_builder_components/CBProfileCard';
import { CBProfileCardLarge } from './content_builder_components/CBProfileCardLarge';
import { CBCounter } from './content_builder_components/CBCounter';
import { CBForm } from './content_builder_components/CBForm';
import { Headline } from './Headline';
import { CBText } from './content_builder_components/CBText';
import { Spacer } from './Spacer';
import { CBMailTemplate } from './content_builder_components/CBMailTemplate';
import { CBBadge } from './content_builder_components/CBBadge';
import { CBCustomerInvitationStateBadge } from './content_builder_components/CBCustomerInvitationStateBadge';
import { CBCustomerDataErrorBadge } from './content_builder_components/CBCustomerDataErrorBadge';
import { CBStatBox } from './content_builder_components/CBStatBox';
import { CBDetails } from './content_builder_components/CBDetails';
import { CBFormattedText } from './content_builder_components/CBFormattedText';
import { CBTableMultilineText } from './content_builder_components/CBTableMultilineText';
import { CBYesNoIcon } from './content_builder_components/CBYesNoIcon';
import { CBEntitlement } from './content_builder_components/CBEntitlement';
import { CBAttachmentList } from './content_builder_components/CBAttachmentList';
import { CBAmount } from './content_builder_components/CBAmount';
import { Heading } from './Heading';
import { CBMetaData } from './content_builder_components/CBMetaData';
import { CBMetaDataChangePercent } from './content_builder_components/CBMetaDataChangePercent';
import { CBDevices } from './content_builder_components/CBDevices';

export default {
    Headline,
    Text,
    CBDevices,
    CBTable,
    CBTab,
    CBProfileCard,
    CBProfileCardLarge,
    CBCounter,
    CBForm,
    CBText,
    Spacer,
    CBMailTemplate,
    CBBadge,
    CBCustomerInvitationStateBadge,
    CBCustomerDataErrorBadge,
    CBStatBox,
    CBDetails,
    CBFormattedText,
    CBTableMultilineText,
    CBYesNoIcon,
    CBEntitlement,
    CBAttachmentList,
    CBAmount,
    CBMetaDataChangePercent,
    CBMetaData,
    Heading,
};

export type CBComponent =
    | 'Headline'
    | 'Heading'
    | 'Text'
    | 'CBTable'
    | 'CBTab'
    | 'CBProfileCard'
    | 'CBProfileCardLarge'
    | 'CBCounter'
    | 'CBForm'
    | 'CBText'
    | 'Spacer'
    | 'CBMailTemplate'
    | 'CBBadge'
    | 'CBCustomerInvitationStateBadge'
    | 'CBCustomerDataErrorBadge'
    | 'CBStatBox'
    | 'CBFormattedText'
    | 'CBTableMultilineText'
    | 'CBDetails'
    | 'CBYesNoIcon'
    | 'CBEntitlement'
    | 'CBAttachmentList'
    | 'CBAmount'
    | 'CBMetaDataChangePercent'
    | 'CBMetaData'
    | 'CBDevices';

// register keys for content builder to ship configuration trough contentbuilder to resulting component
export const CBComponentItems = [
    'form',
    'table',
    'spacer',
    'template',
    'profileCard',
    'details',
    'tab',
    'formattedText',
    'tableMultilineText',
    'profileCardLarge',
    'yesNoIcon',
    'counter',
    'statBox',
    'metaData',
    'amount',
    'cBMetaDataChangePercent',
];
