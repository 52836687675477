//*Dropzone.js*//
import { Box, Center, Input, InputGroup, InputRightElement, SimpleGrid } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import React, { FunctionComponent } from 'react';
import { colors } from '../theme/colors';
import { Text } from './Text';
import { IoCloseOutline } from 'react-icons/io5';

interface Props {
    onUpload: (files: File[]) => void;
    allowedFormat?: string[];
    displayDraggedFiles?: boolean;
    dropzoneHeight?: number;
}

// https://github.com/chidimo/react-dnd/blob/master/src/DragAndDrop.js

export const FileUploadDropzone: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const { onUpload, allowedFormat, dropzoneHeight, displayDraggedFiles = true } = props;

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const reducer = (state: any, action: any) => {
        switch (action.type) {
            case 'SET_DROP_DEPTH':
                return { ...state, dropDepth: action.dropDepth };
            case 'SET_IN_DROP_ZONE':
                return { ...state, inDropZone: action.inDropZone };
            case 'ADD_FILE_TO_LIST':
                const _filesAdd = state.fileList.concat(action.files);
                onUpload(_filesAdd);
                return { ...state, fileList: _filesAdd };
            case 'REMOVE_FILE_FROM_LIST':
                const _filesRemove = state.fileList.filter((_: File, index: number) => index !== action.index);
                onUpload(_filesRemove);
                return { ...state, fileList: _filesRemove };
            default:
                return state;
        }
    };

    const [data, dispatch] = React.useReducer(reducer, { dropDepth: 0, inDropZone: false, fileList: [] });

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const handleDragEnter = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch({ type: 'SET_DROP_DEPTH', dropDepth: data.dropDepth + 1 });
    };

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const handleDragLeave = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch({ type: 'SET_DROP_DEPTH', dropDepth: data.dropDepth - 1 });
        if (data.dropDepth > 0) return;
        dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false });
    };

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const handleDragOver = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        e.dataTransfer.dropEffect = 'copy';
        dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: true });
    };

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const handleDrop = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        let files = [...e.dataTransfer.files, ...data.fileList];
        if (allowedFormat) {
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            files = [...files.filter((value: any) => allowedFormat.some((item) => value.name.endsWith(item)))];
        }

        if (files && files.length > 0) {
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            const existingFiles = data.fileList.map((f: any) => f.name);
            files = files.filter((f) => !existingFiles.includes(f.name));

            dispatch({ type: 'ADD_FILE_TO_LIST', files });
            dispatch({ type: 'SET_DROP_DEPTH', dropDepth: 0 });
            dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false });
        }
    };

    return (
        <>
            <Box
                w="100%"
                p={6}
                height={dropzoneHeight}
                border="2px #c3c3c3 dashed"
                borderRadius={'md'}
                bg={data.inDropZone ? colors.color2 : ''}
                onDrop={(e) => handleDrop(e)}
                onDragOver={(e) => handleDragOver(e)}
                onDragEnter={(e) => handleDragEnter(e)}
                onDragLeave={(e) => handleDragLeave(e)}
            >
                <Center h="100%">
                    <Box>
                        <Text>dragAndDropFiles</Text>
                        {allowedFormat && (
                            <Text align="center">{t('dragAndDropAllowed', { suffix: allowedFormat?.join(', ') })}</Text>
                        )}
                    </Box>
                </Center>
            </Box>
            {displayDraggedFiles && (
                <SimpleGrid pt={4} px={0} columns={3} spacingY={2} spacingX={10}>
                    {data.fileList.map((file: File, index: number) => (
                        <InputGroup key={index}>
                            <Input variant="filled" value={file.name} isReadOnly />
                            <InputRightElement style={{ cursor: 'pointer' }}>
                                <IoCloseOutline
                                    onClick={() => dispatch({ type: 'REMOVE_FILE_FROM_LIST', index })}
                                    color="green.500"
                                />
                            </InputRightElement>
                        </InputGroup>
                    ))}
                </SimpleGrid>
            )}
        </>
    );
};
