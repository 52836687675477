import React, { FunctionComponent } from 'react';
import { Square, Text } from '@chakra-ui/react';
import { AlignRight } from '../AlignRight';
import { Counter } from '../../content_builder_inferface/counter';

interface ProfileCardProps {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    data: number | any[];
    counter?: Counter;
}

export const CBCounter: FunctionComponent<ProfileCardProps> = (props) => {
    const { data, counter } = props;
    const { isBoxed = false, isColored = true } = counter ?? {};

    const _length = Array.isArray(data) ? data.length : data;
    return (
        <AlignRight>
            {isBoxed && (
                <Square size={6} borderRadius={6} bg={_length > 0 && isColored ? 'color4' : 'color11'}>
                    <Text color={'white'}>{_length ?? 0}</Text>
                </Square>
            )}
            {!isBoxed && <Text>{_length}</Text>}
        </AlignRight>
    );
};
