import { UseToastOptions } from '@chakra-ui/react';

export const successFeedback = (
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    toast: any,
    title = 'success',
    toastSettings: UseToastOptions = {
        title: 'success',
        status: 'success',
        duration: 4000,
        isClosable: true,
        position: 'top',
    },
): void => {
    toastSettings.title = title;
    toast(toastSettings);
};

export const errorFeedback = (
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    toast: any,
    title = 'error',
    toastSettings: UseToastOptions = {
        title: 'error',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top',
    },
): void => {
    toastSettings.title = title;
    toast(toastSettings);
};
