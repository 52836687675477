import React, { FunctionComponent, ReactNode } from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerFooter,
} from '@chakra-ui/react';

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    title: ReactNode;
    footer?: ReactNode;
    children: ReactNode;
    size?: Size;
}

export const DetailsDrawer: FunctionComponent<Props> = (props: Props) => {
    const { size = 'md' } = props;

    return (
        <Drawer isOpen={props.isOpen} placement="right" onClose={props.onClose} size={size}>
            <DrawerOverlay>
                <DrawerContent>
                    <DrawerHeader>{props.title}</DrawerHeader>
                    <DrawerCloseButton mt={2} />
                    <DrawerBody>{props.children}</DrawerBody>
                    {props.footer && <DrawerFooter>{props.footer}</DrawerFooter>}
                </DrawerContent>
            </DrawerOverlay>
        </Drawer>
    );
};
