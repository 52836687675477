export const recurrences = {
    Weekly: 'Wöchentlich',
    Monthly: 'Monatlich',
    MidMonthly: 'Monatsmitte',
    Quarterly: 'Vierteljährlich',
    SemiAnnually: 'Halbjährlich',
    Annually: 'Jährlich',
    BiWeekly: 'Zweiwöchentlich',
    BiMonthly: 'Zweimonatlich',
    OneTime: 'Einmalig',
};
