import React, { FunctionComponent } from 'react';
import { Box } from '@chakra-ui/react';
import { Text } from '../Text';
import moment from 'moment';
import { colors } from '../../theme/colors';
import { Amount } from '../../content_builder_inferface/amount';
import { convertCurrencyString } from '../../utils/currency';
import { AlignRight } from '../AlignRight';

interface Props {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    data: any;
    amount: Amount;
}

export const CBAmount: FunctionComponent<Props> = (props) => {
    const { data, amount } = props;

    const _amount = amount.amount in data ? data[`${amount.amount}`] : null;
    const _currency = amount.currency in data ? data[`${amount.currency}`] : null;
    const _date = amount.date && amount.date in data ? data[`${amount.date}`] : null;

    const content = (
        <Box>
            {_amount !== null && _currency !== null && (
                <Text color={data.amount < 0 ? colors.negative : colors.positive}>
                    {convertCurrencyString(_amount, _currency)}
                </Text>
            )}
            {_date && <Text fontSize="sm">vom {moment(_date).format('DD.MM.YYYY')}</Text>}
        </Box>
    );

    if (amount.alignRight) {
        return <AlignRight>{content}</AlignRight>;
    }

    return <Box>{content}</Box>;
};
