import React, { FunctionComponent } from 'react';
import { Flex, Spacer, Stack, Link, Box, Container } from '@chakra-ui/react';
import { NavLink } from './NavLink';
import { NavGroup } from './NavGroup';
import { IoLogOut, IoInformationCircle, IoPlay } from 'react-icons/io5';
import { Link as RouterLink } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useKeycloak } from '@react-keycloak/web';
import { getUser, checkScope } from '../../keycloak';
import { Logo } from '../Logo';
import { ProfileCard } from '../ProfileCard';
import { baseElements, groupedNavElements, NavigationElement } from '../../Navigation';
import { openUrlInNewTab } from '../../utils/openUrl';
import { isCodie } from '../../appBrand';

const generateNavElement: FunctionComponent<NavigationElement> = (navElement: NavigationElement) => {
    let linkProps: unknown = {
        to: navElement.link,
        as: RouterLink,
    };
    if (navElement.external) {
        linkProps = {
            isExternal: true,
            href: navElement.link,
        };
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <NavLink label={navElement.label} icon={navElement.icon} key={uuidv4()} {...linkProps} />;
};

const generateNavLinks = (elements: NavigationElement[], userScopes: string[]) => {
    return (
        elements
            // only show allowed elements for user
            .filter((element) => element.scopes == undefined || checkScope(userScopes, element.scopes))
            .map((element) => generateNavElement(element))
    );
};

const generateNavigation = (userScopes: string[]) => {
    const navigation = [];
    navigation.push(
        <Stack key={uuidv4()} spacing="1">
            {generateNavLinks(baseElements, userScopes)}
        </Stack>,
    );
    for (const key of Object.keys(groupedNavElements)) {
        const navLinks = generateNavLinks(groupedNavElements[key], userScopes);
        if (navLinks.length) {
            navigation.push(
                <NavGroup key={uuidv4()} label={key}>
                    {navLinks}
                </NavGroup>,
            );
        }
    }
    return navigation;
};

export const Sidebar: FunctionComponent = () => {
    const { keycloak } = useKeycloak();
    const user = getUser(keycloak);

    return (
        <Flex direction="column" borderRightWidth="1px" width="64" bg="color2">
            <Container pt="3" pb="4">
                <Link as={RouterLink} to="/">
                    <Logo />
                </Link>
            </Container>
            {keycloak.authenticated && (
                <Stack spacing="6" as="nav" px={2} aria-label="Sidebar Navigation">
                    {user && generateNavigation(user?.roles)}
                </Stack>
            )}
            <Spacer />
            {isCodie && <NavLink mx="2" my="3" label="Video-Anleitung" icon={IoPlay} as={RouterLink} to="/video360" />}
            {isCodie && (
                <NavLink
                    mx="2"
                    my="3"
                    label="App-Hilfe"
                    icon={IoInformationCircle}
                    onClick={() => openUrlInNewTab('https://hilfe.app.fp-finanzpartner.de')}
                />
            )}
            {isCodie && (
                <NavLink
                    mx="2"
                    my="3"
                    label="Dashboard-Hilfe"
                    icon={IoInformationCircle}
                    onClick={() => openUrlInNewTab('https://hilfe.dashboard.app.fp-finanzpartner.de')}
                />
            )}
            <NavLink
                mx="2"
                my="3"
                label="logout"
                icon={IoLogOut}
                onClick={() => keycloak.logout({ redirectUri: window.location.origin })}
            />
            {keycloak.authenticated && (
                <Box p="4" bg="color1">
                    <ProfileCard firstName={user?.name} email={user?.email} />
                </Box>
            )}
        </Flex>
    );
};
