/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export function createYupSchema(schema: any, config: any) {
    const { id, validationType, validations = [] } = config;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    if (!(yup as any)[validationType]) {
        return schema;
    }
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    let validator = (yup as any)[validationType]();
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    validations.forEach((validation: any) => {
        const { params, type } = validation;
        if (!validator[type]) {
            return;
        }
        validator = validator[type](...params);
    });

    schema[id] = validator;
    return schema;
}
