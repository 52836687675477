export const colors = {
    color1: '#dee9f7',
    color2: '#f4f7fb',
    color3: '#dee9f770',
    color4: '#3182ce',
    color5: '#2b6cb0', // Highlight
    color6: '#718096', // inactive, placeholder
    color7: '#2d3748',
    color8: '#cbd5e0',
    color9: '#4a5568',
    color10: '#1a202c',
    color11: '#bec1c4',
    color12: '#EDF2F7',
    positive: '#44bc4a',
    negative: '#d60808',
};
