import { extendTheme } from '@chakra-ui/react';
import { colors } from './colors';
import { components } from './components';

const theme = extendTheme({
    fonts: {
        body: 'Roboto, sans-serif',
        heading: 'Roboto, sans-serif',
    },
    colors: colors,
    components: {
        ...components,
    },
});

export default theme;
