import { Box, Center, Container, Flex } from '@chakra-ui/react';
import React, { FunctionComponent } from 'react';
import { default as _ReactPlayer } from 'react-player/lazy';
import { ReactPlayerProps } from 'react-player/types/lib';
const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

export const Video360: FunctionComponent = () => {
    return (
        <Box mx="auto" w={'full'}>
            <ReactPlayer
                url="https://vimeo.com/811321076/d919974c73?"
                controls={true}
                width="100%"
                height="100%"
                playing={true}
            />
        </Box>
    );
};
