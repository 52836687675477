import { Config } from '../../content_builder_inferface';

export const config: Config = {
    table: [
        {
            as: 'CBTable',
            data: 'advisors',
            table: {
                search: { show: true },
                alternativeUUID: 'advisorId',
                columns: [
                    { name: 'fullName' },
                    { name: 'email' },
                    { name: 'mobilePhone' },
                    { name: 'faxPhone' },
                    { name: 'displayAddress' },
                ],
            },
        },
    ],
};
