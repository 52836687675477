import React, { FunctionComponent, ReactNode } from 'react';
import { Button as ChakraButton, ButtonProps } from '@chakra-ui/react';
import { useTranslation, Trans } from 'react-i18next';

interface Props extends ButtonProps {
    children: string | number | ReactNode | ReactNode[];
}

export const Button: FunctionComponent<Props> = (props) => {
    const { children, loadingText, ...rest } = props;
    const { t } = useTranslation();
    return (
        <ChakraButton {...rest} loadingText={t(loadingText as string)}>
            {typeof children !== 'string' && typeof children === 'number' ? children : <Trans>{children}</Trans>}
        </ChakraButton>
    );
};
