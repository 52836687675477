import { FunctionComponent, useEffect, useState } from 'react';
import { Box, useDisclosure } from '@chakra-ui/react';
import { ContentContainer } from '../../components/ContentContainer';
import { Breadcrumbs } from '../../components/navigation/Breadcrumbs';
import { useParams } from 'react-router-dom';
import { config as appCustomerDetails } from '../../content_builder_config/customer/appCustomerDetails';
import { ContentBuilder } from '../../components/ContentBuilder';
import { converter } from '../../converter';
import { RawData } from '../../converter/Interface';
import {
    CommunicationThread,
    DashboardCustomerDetail,
    InvitationState,
    useDashboardCustomerCheckoutLatestMetaDataMutation,
    useDashboardSetCommunicationReadingStateMutation,
    useGetDashboardCustomerDetailQuery,
    useDashboardCustomerMetaDataToggleIgnoreMutation,
    useDashboardCustomerUpdateSensitivePersonMutation,
    AgreementProcessMetadataBehaviorType,
    useDashboardCustomerRequestMetaDataMutation,
    useDashboardUndoInviteForAdminMutation,
} from '../../generated/types';
import LoadingIndicator from '../../components/LoadingIndicator';
import ErrorBox from '../../components/ErrorBox';
import { Account } from '../../interfaces/account';
import { useKeycloak } from '@react-keycloak/web';
import { checkScope, getUser } from '../../keycloak';
import { MessagesOverlay, readingStateScopes } from '../../components/MessagesOverlay';
import { isCodie } from '../../appBrand';
import { useToast } from '@chakra-ui/react';
import { successFeedback } from '../../helper/feedback';
import { useTranslation } from 'react-i18next';

const infoUrl = isCodie
    ? 'https://hilfe.dashboard.app.fp-finanzpartner.de/sl-SI/kb/articles/welche-kundendaten-kann-ich-ber-das-berater-dashboard-einsehen-und-bearbeiten'
    : undefined;

type RouteParams = {
    id: string;
};

type UnkownMetaData = { metaDataId: string } & { [key: string]: string };

export const CustomerDetails: FunctionComponent = () => {
    // config is based on route
    //

    // Previous
    // const { pathname } = useLocation();
    // const config = pathname.startsWith('/invite') ? inviteCustomerDetails : appCustomerDetails;
    //
    // Always show all tabs on invite and customer details endpoint
    const config = appCustomerDetails;
    const { id } = useParams<RouteParams>();
    const { keycloak } = useKeycloak();
    const user = getUser(keycloak);
    const toast = useToast();
    const { t } = useTranslation();

    const appDataViewScope = ['walt_admin', 'walt_supervisor', 'walt_fp_supervisor', 'walt_advisor'];

    const [data, setData] = useState<DashboardCustomerDetail | undefined>();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [resultProfileQuery, reexecuteProfileQuery] = useGetDashboardCustomerDetailQuery({
        variables: { customerIdentifier: id ?? '' },
    });

    const [, setCommunicationReadingState] = useDashboardSetCommunicationReadingStateMutation();
    const [metaDataToggleIgnore, executeMetaDataToggleIgnore] = useDashboardCustomerMetaDataToggleIgnoreMutation();
    const [, executeCustomerSensitivePersion] = useDashboardCustomerUpdateSensitivePersonMutation();
    const [, executeUndoInviteForAdmin] = useDashboardUndoInviteForAdminMutation();
    const [checkoutLatestMetaData, executeCheckoutLatestMetaData] =
        useDashboardCustomerCheckoutLatestMetaDataMutation();
    const [, executeCustomerRequestMetaData] = useDashboardCustomerRequestMetaDataMutation();
    const [selectedThread, setSelectedThread] = useState<CommunicationThread>();
    const { data: profileQueryData, fetching, error } = resultProfileQuery;

    const updateSensitiveUser = async (sensitivePersonForeignId: string): Promise<void> => {
        const result = await executeCustomerSensitivePersion({ personForeignId: id ?? '', sensitivePersonForeignId });
        setData(result.data?.dashboardCustomerUpdateSensitivePerson as DashboardCustomerDetail);
    };

    const undoInviteMethod = async (): Promise<void> => {
        const result = await executeUndoInviteForAdmin({ personForeignId: id ?? '' });
        successFeedback(toast, t('undoInviteSuccess'));
        setData(result.data?.dashboardUndoInviteForAdmins as DashboardCustomerDetail);
    };

    useEffect(() => {
        setData(profileQueryData?.dashboardCustomerDetail as DashboardCustomerDetail);
    }, [profileQueryData]);

    const closeMessageWindow = () => {
        setSelectedThread(undefined);
        onClose();
    };

    useEffect(() => {
        async function doMarkThreadAsRead() {
            if (selectedThread) {
                await markThreadAsRead(selectedThread?.communicationThreadId, true);
            }
        }

        doMarkThreadAsRead();
    }, [selectedThread]);

    if (fetching) return <LoadingIndicator />;
    if (error) return <ErrorBox message={error.message} />;
    if (!data) return null;

    const do_metaDataIgnore = async (item: UnkownMetaData): Promise<void> => {
        if (id) {
            const result = await executeMetaDataToggleIgnore({ customerIdentifier: id, metaDataId: item.metaDataId });
            setData(result.data?.dashboardCustomerMetaDataToggleIgnore as DashboardCustomerDetail);
        }
    };

    const do_checkoutLatestMetaData = async (): Promise<void> => {
        if (id && data?.customer.advisorId) {
            const result = await executeCheckoutLatestMetaData({
                customerIdentifier: id,
                advisorId: data?.customer.advisorId,
            });
            setData(result.data?.dashboardCustomerCheckoutLatestMetaData as DashboardCustomerDetail);
        }
    };

    const do_requestData = async (): Promise<void> => {
        if (data?.customer.advisorId && id) {
            await executeCustomerRequestMetaData({
                customerIdentifier: id,
                advisorId: data.customer.advisorId,
            });
        }
        return;
    };

    const customer = data?.customer;
    const threads = data?.communication.threads;

    const webconnectAccounts = converter<Account[]>(data?.accounts as RawData[], {
        date: { dateFormat: 'DD.MM.YY' },
        amount: { output: 'toLocaleString' },
        string: { translationFields: ['recurrence'] },
    });

    const contracts = webconnectAccounts.find((account) => account.supportsContracts)?.contracts || [];
    const accounts = webconnectAccounts
        .filter((account) => !account.supportsContracts && account.supportsBalances)
        .map((account) => ({
            ...account,
            balance: account.balances[0].balance,
            balanceDate: account.balances[0].balanceDate,
        }));

    const markThreadAsRead = async (threadId: string, isRead: boolean): Promise<void> => {
        if (!checkScope(user.roles, readingStateScopes)) return;

        const result = await setCommunicationReadingState({ threadId: threadId, isRead: isRead });
        if (selectedThread && result.data?.dashboardSetCommunicationReadingState === 'success') {
            selectedThread.advisorRead = isRead;
        }
    };

    const onPressMessage = async (data: CommunicationThread): Promise<void> => {
        await setSelectedThread(data);
        onOpen();
    };

    const tabMessageVisible = (): boolean => {
        return (
            data?.customer.invitationState == InvitationState.Accepted ||
            data?.customer.invitationState == InvitationState.Appinuse
        );
    };

    const tabMetaDataVisible = (): boolean => {
        // No everyone must see the app data tab
        if (!checkScope(user.roles, appDataViewScope)) {
            return false;
        }

        // If customer consent is set, we need to show the tab.
        if (
            data.metaData?.agreementProcessMetadataBehaviorType === AgreementProcessMetadataBehaviorType.CustomerConsent
        ) {
            return true;
        }

        return !!data.metaData?.lastRequestedDate || !!data.metaData?.lastAvailableDate;
    };

    return (
        <ContentContainer headline="customer" description="customerDescription" infoUrl={infoUrl}>
            <Box mb={10}>
                <Breadcrumbs
                    items={[
                        { label: 'customers', href: './' },
                        { label: [customer?.firstName, customer?.lastName].join(' ') },
                    ]}
                />
            </Box>
            {customer && (
                <ContentBuilder
                    config={config.customer}
                    data={{
                        customer,
                        sensibel: {
                            possibleSensibleReaders: data.possibleSensibleReaders,
                            sensibleReader: data.sensibleReader,
                        },
                    }}
                    methods={{ newMessage: onOpen, updateSensitiveUser, undoInviteMethod }}
                />
            )}
            <ContentBuilder
                config={config.tabs}
                data={{
                    accounts,
                    contracts,
                    threads,
                    customer,
                    entitlements: {
                        entitlement: data?.settings.entitlements,
                        foreignId: id,
                    },
                    devices: data?.customer.devices,
                    metaData: {
                        fetching: metaDataToggleIgnore.fetching || checkoutLatestMetaData.fetching,
                        ...data?.metaData,
                    },
                }}
                methods={{
                    onPressMessage,
                    tabMessageVisible,
                    tabMetaDataVisible,
                    do_checkoutLatestMetaData,
                    do_metaDataIgnore,
                    do_requestData,
                }}
            />

            <MessagesOverlay
                isOpen={isOpen}
                selectedThread={selectedThread}
                closeMessageWindow={closeMessageWindow}
                advisorId={data?.customer.advisorId}
                customer={{
                    id: id ?? '',
                    name: data?.customer.fullName ?? '',
                }}
                markThreadAsRead={markThreadAsRead}
                reexecuteQuery={reexecuteProfileQuery}
                customerSearchAble={true}
            />
        </ContentContainer>
    );
};
