import { AttachmentInput } from '../generated/types';

export const formatBytes = (bytes: number, decimals = 0) => {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const base64toBlob = (data: string, contentType: string) => {
    const bytes = atob(data);

    let length = bytes.length;

    const out = new Uint8Array(length);

    while (length--) {
        out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: contentType });
};

export const createAttachmentInputFromFileObject = async (file: File): Promise<AttachmentInput | never> => {
    const base64 = await convertBase64(file);

    if (!base64) throw new Error('File Object could not be converted to AttachmentInput');
    return {
        dataBase64: base64.toString().split(',')[1],
        fileName: file.name,
        mimeType: file.type,
    };
};

const convertBase64 = (file: File): Promise<ArrayBuffer | never> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result as ArrayBuffer);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};
