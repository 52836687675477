import React, { FunctionComponent, ReactNode } from 'react';
import { Divider } from '@chakra-ui/react';
import { Heading } from './Heading';

interface Props {
    headline: string;
    children: string | ReactNode | ReactNode[];
}

export const Headline: FunctionComponent<Props> = (props) => {
    return (
        <>
            <Heading variant="heading">{props.children}</Heading>
            <Divider mt={2} mb={6} borderColor="color8" />
        </>
    );
};
