/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FunctionComponent } from 'react';
import { ConfigItem } from '../content_builder_inferface';
import Components, { CBComponentItems } from './';

interface Props {
    config: ConfigItem[];
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    data?: any;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    methods?: { [key: string]: any };
}
// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const ContentBuilder: FunctionComponent<Props> = (props): any => {
    const { config, data, methods } = props;

    if (!config) return null;

    return (
        <>
            {config.map((item: ConfigItem, index) => {
                const key = `${item.as}_${index}`;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const Component: ReactNode = Components[item.as];
                let rest = {};

                CBComponentItems.forEach((key: string) => {
                    if (key in item) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        rest = { ...rest, [key]: item[key] };
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        if ('methods' in item[key]) rest = { methods, ...rest };
                    }
                });

                // Append data, methods to rest props if exists
                if ('data' in item) rest = { data: item.data === 'self' ? data : data[`${item.data}`], ...rest };
                rest = { methods, ...rest };

                // Fill rest with basic data if no data is given
                if (data && !('data' in rest)) rest = { ...rest, data };

                return (
                    <Component {...item.props} key={key} {...rest}>
                        {item.children ? <ContentBuilder config={item.children} data={data} /> : item.content}
                    </Component>
                );
            })}
        </>
    );
};
