import React, { FunctionComponent } from 'react';
import { ContentBuilder } from '../../components/ContentBuilder';
import { ContentContainer } from '../../components/ContentContainer';
import { config } from '../../content_builder_config/advisors/details';
import { useParams } from 'react-router-dom';
import { useDashboardAdvisorDetailsQuery } from '../../generated/types';
import LoadingIndicator from '../../components/LoadingIndicator';
import ErrorBox from '../../components/ErrorBox';
import { isCodie } from '../../appBrand';

const infoUrl = isCodie
    ? '"https://hilfe.dashboard.app.fp-finanzpartner.de/sl-SI/kb/articles/wie-kann-ich-mein-foto-und-meine-kontaktdaten-in-der-fp-360-app-anzeigen"'
    : undefined;

type RouteParams = {
    id?: string;
};

export const Details: FunctionComponent = () => {
    const { id } = useParams<RouteParams>();

    const [resultAdvisorDetailsQuery] = useDashboardAdvisorDetailsQuery({
        variables: { advisorId: id ?? '' },
    });

    const { data, fetching, error } = resultAdvisorDetailsQuery;

    if (fetching) return <LoadingIndicator />;
    if (error) return <ErrorBox message={error.message} />;
    if (!data) return null;

    const advisor = data.dashboardAdvisorDetails;

    return (
        <ContentContainer headline="advisor" description="advisorDescription" infoUrl={infoUrl}>
            {advisor && (
                <ContentBuilder
                    config={advisor.isCompany ? config.advisorCompany : config.advisor}
                    data={{
                        customer: advisor,
                        sensibel: {
                            sensibleReader: undefined,
                            possibleSensibleReaders: [],
                        },
                    }}
                    methods={{}}
                />
            )}
        </ContentContainer>
    );
};
