import React, { FunctionComponent } from 'react';
import { Box } from '@chakra-ui/react';
import { Text } from './Text';
import { colors } from './../theme/colors';
import { convertCurrencyString } from './../utils/currency';

interface Props {
    amount: number;
    currency: string;
}

export const Amount: FunctionComponent<Props> = (props) => {
    const { amount, currency } = props;

    return (
        <Box>
            <Text color={amount < 0 ? colors.negative : colors.positive}>
                {convertCurrencyString(amount, currency)}
            </Text>
        </Box>
    );
};
