import { Config } from '../../content_builder_inferface';

export const config: Config = {
    table: [
        {
            as: 'CBTable',
            data: 'customers',
            table: {
                search: {
                    show: false,
                },
                columns: [
                    {
                        name: 'nameEmail',
                        component: {
                            as: 'CBProfileCard',
                            data: 'self',
                            profileCard: { showState: false },
                        },
                    },
                ],
                isRowClickable: false,
            },
        },
        {
            as: 'Spacer',
            spacer: {
                height: 50,
            },
        },
    ],
    invite: [
        {
            as: 'CBMailTemplate',
            data: 'invite',
            template: {
                additionalButtons: [
                    {
                        label: 'cancel',
                        onClick: 'cancel',
                    },
                ],
                submitButton: {
                    label: 'invite',
                    onClick: 'submit',
                },
            },
        },
    ],
};
