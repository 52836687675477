import React, { FunctionComponent, ReactNode } from 'react';
import { Text as ChakraText, TextProps } from '@chakra-ui/react';
import { Trans } from 'react-i18next';

interface Props extends TextProps {
    children: string | number | ReactNode | ReactNode[];
}

export const Text: FunctionComponent<Props> = (props) => {
    const { children, ...rest } = props;

    return (
        <ChakraText {...rest} style={{ whiteSpace: 'pre-wrap' }}>
            {typeof children !== 'string' && typeof children === 'number' ? children : <Trans>{children}</Trans>}
        </ChakraText>
    );
};
