import { colors } from '../colors';

export const button = {
    baseStyle: {
        color: colors.color10,
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 'normal',
    },
    sizes: {},
    variants: {
        submit: {
            color: 'white',
            bg: colors.color4,
            _hover: {
                _disabled: {
                    color: 'white',
                    bg: colors.color4,
                },
            },
        },
        link: {
            color: colors.color5,
        },
        ghost: {
            color: colors.color7,
        },
        discreet: {
            bg: colors.color1,
        },
        outlineCustom: {
            border: '1px solid',
            borderColor: 'gray.800',
            color: 'gray.800',
            _hover: {
                border: '1px solid',
                borderColor: 'gray.800',
                color: 'gray.800',
            },
        },
    },
};
