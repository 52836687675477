import { Stack } from '@chakra-ui/react';
import React, { FunctionComponent } from 'react';
import { Details } from '../../content_builder_inferface/details';
import { formatter } from '../../helper/formatter';
import { DetailsElement } from '../DetailsElement';

interface Props {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    data: { [key: string]: any };
    details: Details;
}

export const CBDetails: FunctionComponent<Props> = (props) => {
    const { details, data } = props;
    const { detail_keys, format } = details;

    return (
        <Stack>
            {detail_keys.map((item) => {
                const value =
                    item in format ? formatter(data[item], format[item].type, format[item].format) : data[item];
                return value && <DetailsElement label={item} key={item} value={value} />;
            })}
        </Stack>
    );
};
