import React, { FunctionComponent, ReactNode } from 'react';
import { Box, Spacer, BoxProps } from '@chakra-ui/react';

interface Props extends BoxProps {
    children: ReactNode | ReactNode[];
}

export const AlignRight: FunctionComponent<Props> = (props) => {
    const { children, ...rest } = props;
    return (
        <Box display="flex" {...rest}>
            <Spacer />
            {children}
        </Box>
    );
};
