export const budgetCategories = {
    b_general: 'Andere',
    b_car: 'Auto',
    b_clothing: 'Bekleidung',
    b_groceries: 'Lebensmittel',
    b_health: 'Gesundheit',
    b_leisure: 'Freizeit',
    b_media: 'Medien',
    b_restaurant: 'Restaurant',
    b_travel: 'Reisen',
    b_credit: 'Kredit',
    b_music: 'Musik',
    b_video: 'Video',
    b_electricity: 'Strom',
    b_gas: 'Gas',
    b_water: 'Wasser',
    b_budget: 'Budget',
    b_salary: 'Einkommen',
    b_childcare: 'Kinder',
    b_unemployment: 'Arbeitslosigkeit',
    b_internet: 'Internet',
    b_phone: 'Telefon',
    b_cloud: 'Cloud',
};
