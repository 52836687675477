import { Config } from '../content_builder_inferface';
import { Formatter } from '../helper/formatter';

export const config: Config = {
    selectedThread: [
        {
            as: 'CBTable',
            data: 'messages',
            table: {
                header: {
                    sender: {
                        maxWidth: 100,
                    },
                    date: {
                        maxWidth: 200,
                    },
                },
                isRowClickable: false,
                defaultPageSize: 5,
                initialState: {
                    sortBy: [{ id: 'dateString', desc: true }],
                },
                columns: [
                    {
                        name: 'date',
                        component: {
                            as: 'CBFormattedText',
                            data: 'dateString',
                            formattedText: {
                                format: { type: Formatter.DATE, format: 'DD.MM.YY HH:mm' },
                            },
                        },
                        searchColumn: 'dateString',
                    },
                    {
                        name: 'sender',
                        component: {
                            as: 'CBText',
                            data: 'sender',
                        },
                        searchColumn: 'sender',
                    },
                    {
                        name: 'message',
                        component: {
                            as: 'CBTableMultilineText',
                            data: 'message',
                            tableMultilineText: {
                                indicator: '\n',
                            },
                        },
                        searchColumn: 'message',
                    },
                    {
                        name: 'attachments',
                        component: {
                            as: 'CBAttachmentList',
                            data: 'attachments',
                        },
                    },
                ],
            },
        },
    ],
};
