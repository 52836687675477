import { colors } from '../colors';

export const text = {
    baseStyle: {
        fontSize: 'md',
        fontWeight: 'normal',
        lineHeight: 1.5,
        color: colors.color10,
    },
    variants: {
        uppercase: {
            fontSize: 'sm',
            color: colors.color6,
            textTransform: 'uppercase',
        },
        subText: {
            fontSize: 'sm',
            color: colors.color6,
        },
        label: {
            fontSize: 'xs',
            color: colors.color6,
        },
        tabLabel: {
            fontSize: 'lg',
        },
    },
};
