export const contractCategories = {
    budget: 'Budget (Lebenskosten, …)',
    childcare: 'Kinderbetreuung',
    communication: 'Kommunikation',
    communication_cloudstorage: 'Cloudspeicher',
    communication_internet: 'Internet',
    communication_mobile: 'Mobiltelefon',
    communication_other: 'Andere',
    communication_phone: 'Telefon',
    entertainment: 'Unterhaltung',
    entertainment_music: 'Musik Streaming',
    entertainment_other: 'Unterhaltung (Andere)',
    entertainment_paytv: 'Pay TV',
    entertainment_televisiontax: 'Rundfunkbeitrag',
    entertainment_video: 'Video Streaming',
    fees: 'Bankgebühren',
    general: 'Andere',
    health: 'Gesundheit',
    income: 'Einkommen',
    income_childsupport: 'Kindergeld',
    income_other: 'Einkommen (Andere)',
    income_retirement: 'Rente',
    income_salary: 'Lohn/Gehalt',
    income_unemployment: 'Arbeitslosengeld',
    insurance: 'Versicherung',
    insurance_car: 'Auto',
    insurance_damages: 'Haftpflicht',
    insurance_health: 'Gesundheit',
    insurance_home: 'Hausrat',
    insurance_legal: 'Rechtsschutz',
    insurance_life: 'Leben',
    insurance_loan: 'Kredite',
    insurance_other: 'Versicherung (Andere)',
    insurance_retirement: 'Rente',
    lease: 'Leasing',
    lease_car: 'Auto',
    lease_other: 'Leasing (Andere)',
    loan: 'Kredit',
    loan_buildingsavings: 'Bausparvertrag',
    loan_car: 'Auto',
    loan_debtconversion: 'Umschuldung',
    loan_entertainment: 'Entertainment',
    loan_home: 'Möbel/Renovierung',
    loan_other: 'Kredit (Freie Verfügung)',
    loan_realestate: 'Immobilie',
    loan_student: 'Student',
    rent: 'Hypothek / Miete',
    savings: 'Sparen',
    utilities: 'Energie',
    utilities_electricity: 'Strom',
    utilities_gas: 'Gas',
    utilities_oil: 'Öl',
    utilities_other: 'Energie (Andere)',
    utilities_water: 'Wasser',
};
