import React, { FunctionComponent } from 'react';
import { ProfileCard as ProfileCardConfig } from '../../content_builder_inferface/profileCard';
import { ProfileCard } from '../ProfileCard';

interface ProfileCardProps {
    data: ProfileCard;
    profileCard?: ProfileCardConfig;
}

export const CBProfileCard: FunctionComponent<ProfileCardProps> = (props) => {
    return <ProfileCard {...props.data} showState={props.profileCard?.showState} />;
};
