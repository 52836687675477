enum AppBrand {
    codie = 'codie',
    finaffin = 'finaffin',
}
export const appBrand: AppBrand = `${import.meta.env.VITE_BRAND}` as AppBrand;
export const isCodie = appBrand === AppBrand.codie;
export const isFinAffin = appBrand === AppBrand.finaffin;

export interface SentryParameters {
    enabled: boolean;
    dsn: string;
    tracingOrigins: Array<string | RegExp>;
}

export interface KeycloakParameters {
    realm: string;
    url: string;
    clientId: string;
}
export interface BackendParameters {
    serverUrl: string;
}
export interface BrandParameters {
    sentry: SentryParameters;
    keycloak: KeycloakParameters;
    backend: BackendParameters;
}

const brandParametersConfigs: Record<AppBrand, BrandParameters> = {
    [AppBrand.codie]: {
        sentry: {
            enabled: true,
            dsn: 'https://44d32d03ba504236b2e1fbf007bb5b51@s.outbankapp.com/39',
            tracingOrigins: ['localhost', /^\//, 'client.app.fp-finanzpartner.de', 'dashboard.app.fp-finanzpartner.de'],
        },
        keycloak: {
            realm: 'Finanzpartner',
            url: 'https://kc.app.fp-finanzpartner.de',
            clientId: 'dashboard',
        },
        backend: {
            serverUrl: 'https://client.app.fp-finanzpartner.de/graphql',
        },
    },
    [AppBrand.finaffin]: {
        sentry: {
            enabled: true,
            dsn: 'https://25e359c8679e44b7934a92d8bcdff40b@sentry.outbankapp.com/43',
            tracingOrigins: ['localhost', /^\//, 'client.finaffin.de', 'dashboard.finaffin.de'],
        },
        keycloak: {
            realm: 'Finaffin',
            url: 'https://login.finaffin.de',
            clientId: 'dashboard',
        },
        backend: {
            serverUrl: 'https://client.finaffin.de/graphql',
        },
    },
};

export const brandParameters: BrandParameters = brandParametersConfigs[appBrand];
