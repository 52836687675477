import React, { FunctionComponent, ReactNode } from 'react';
import { Box, Flex, Stack } from '@chakra-ui/react';
import { Text } from './Text';
import { Heading } from './Heading';
import { Button } from './Button';
import { openUrlInNewTab } from '../utils/openUrl';

interface Props {
    headline: string;
    description: string;
    infoUrl?: string;
    children?: ReactNode;
}

export const ContentContainer: FunctionComponent<Props> = (props: Props) => {
    const { headline, description, infoUrl } = props;
    return (
        <Flex w="full" h="full" flexDirection="column">
            <Stack py={8} px={20} bg="color1">
                <Stack direction="row" bg="yelllow">
                    <Box flex={1}>
                        <Heading variant="title">{headline}</Heading>

                        {description && (
                            <Text pb="2" color="color6">
                                {description}
                            </Text>
                        )}
                    </Box>
                    {infoUrl && (
                        <Button variant="outlineCustom" onClick={() => openUrlInNewTab(infoUrl)}>
                            Help
                        </Button>
                    )}
                </Stack>
            </Stack>
            <Box flex="1" my={10} mx={20}>
                {props.children}
            </Box>
        </Flex>
    );
};
