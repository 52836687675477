import Keycloak from 'keycloak-js';
import { brandParameters } from './appBrand';

export const keycloakObject = new Keycloak({
    realm: brandParameters.keycloak.realm,
    url: brandParameters.keycloak.url,
    clientId: brandParameters.keycloak.clientId,
});

interface User {
    name: string;
    email: string;
    roles: string[];
}

export const getUser = (keycloak: Keycloak): User => {
    const parsedUser = keycloak.tokenParsed as {
        name: string;
        email: string;
        groups: string[];
    };

    storedSessionUser = {
        name: parsedUser?.name,
        email: parsedUser?.email,
        roles: parsedUser?.groups,
    };

    return storedSessionUser;
};

export const checkScope = (userScopes: string[], neededScopes: string[]): boolean => {
    return userScopes.some((item) => neededScopes.includes(item));
};

export let storedSessionUser: User | null = null;
