import React, { FunctionComponent, ReactNode } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { Text } from './Text';

interface Props {
    label: string;
    value?: string | number;
    children?: ReactNode;
    boxProps?: BoxProps;
}

export const DetailsElement: FunctionComponent<Props> = (props: Props) => {
    return (
        <Box {...props.boxProps}>
            <Text variant="label">{props.label}</Text>
            {props.value && <Text>{props.value}</Text>}
            {props.children}
        </Box>
    );
};
