import { Config } from '../../content_builder_inferface';
import { Formatter } from '../../helper/formatter';

export const config: Config = {
    table: [
        {
            as: 'CBTable',
            data: 'threads',
            table: {
                upperTableButton: {
                    label: 'newMessage',
                    methodName: 'newMessage',
                },
                rowClick: 'onPressMessage',
                header: {
                    messages: {
                        textAlign: 'right',
                    },
                    messageCount: {
                        textAlign: 'right',
                    },
                    date: {
                        maxWidth: 200,
                    },
                    advisorRead: {
                        maxWidth: 60,
                    },
                },
                search: {
                    show: true,
                    filter: {
                        method: 'searchFilterValues',
                        column: 'advisorRead',
                    },
                },
                columns: [
                    {
                        name: 'advisorRead',
                        component: {
                            as: 'CBYesNoIcon',
                            data: 'advisorRead',
                            yesNoIcon: {
                                asBobble: true,
                                isInverted: true,
                            },
                        },
                    },
                    {
                        name: 'date',
                        component: {
                            as: 'CBFormattedText',
                            data: 'dateString',
                            formattedText: {
                                format: { type: Formatter.DATE, format: 'DD.MM.YY HH:mm' },
                            },
                        },
                        searchColumn: 'dateString',
                    },
                    { name: 'customer.fullName' },
                    { name: 'subject' },
                    {
                        name: 'userRead',
                        component: {
                            as: 'CBYesNoIcon',
                            data: 'userRead',
                        },
                    },
                    {
                        name: 'messageCount',
                        component: {
                            as: 'CBCounter',
                            data: 'countEntries',
                            counter: {
                                isBoxed: true,
                                isColored: false,
                            },
                        },
                    },
                ],
            },
        },
    ],
};
