import React, { FunctionComponent } from 'react';
import Spinner from './Spinner';

const LoadingIndicator: FunctionComponent = () => {
    return (
        <div
            style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
            }}
        >
            <Spinner />
        </div>
    );
};

export default LoadingIndicator;
