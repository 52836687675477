import React, { FunctionComponent, useState } from 'react';
import { Column } from 'react-table';
import {
    DashboardCustomerOrderBy,
    DashboardCustomerWithInvitationStateWhereListInput,
    useGetDashboardCustomersForAdvisorQuery,
    DashboardSortOrder,
} from '../generated/types';
import { TableContainerServerSide } from './table/TableContainerServerSide';

interface Props {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    columns: Column<any>[];
    defaultFilter?: DashboardCustomerWithInvitationStateWhereListInput;
    defaultPageSize?: number;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    rowClick?: (original: any) => void;
    useTableSpinner?: boolean;
}

export const CustomerTable: FunctionComponent<Props> = (props: Props) => {
    const { columns, defaultFilter, defaultPageSize = 15, useTableSpinner = false, rowClick } = props;

    const [queryOrderBy, setQueryOrderBy] = useState<DashboardCustomerOrderBy>({ fullName: DashboardSortOrder.Asc });
    const [queryPageIndex, setQueryPageIndex] = useState<number>(0);
    const [queryPageSize, setQueryPageSize] = useState<number>(defaultPageSize);
    const [queryPageFilteredFields, setQueryPageFilteredFields] =
        useState<DashboardCustomerWithInvitationStateWhereListInput>();

    const [resultProfileQuery, _reexecuteProfileQuery] = useGetDashboardCustomersForAdvisorQuery({
        variables: {
            paginationData: { page: queryPageIndex, size: queryPageSize },
            where: queryPageFilteredFields ?? { whereList: defaultFilter?.whereList ? defaultFilter.whereList : [] },
            orderBy: queryOrderBy,
        },
    });

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const orderBy = (sortBy: { [key: string]: any }[]) => {
        // reset
        if (sortBy.length === 0) {
            setQueryOrderBy({ fullName: DashboardSortOrder.Asc });
            return;
        }

        // convert
        const mapped = sortBy.map((item) => {
            return { [item.id]: item.desc ? DashboardSortOrder.Desc : DashboardSortOrder.Asc };
        });
        const converted = Object.assign({}, ...mapped);
        setQueryOrderBy(converted);
    };

    const { data, fetching, error } = resultProfileQuery;

    return (
        <TableContainerServerSide
            columns={columns}
            defaultFilter={defaultFilter}
            data={data}
            error={error}
            fetching={fetching}
            queryPageIndex={queryPageIndex}
            queryPageSize={queryPageSize}
            orderBy={orderBy}
            setQueryPageFilteredFields={setQueryPageFilteredFields}
            setQueryPageIndex={setQueryPageIndex}
            setQueryPageSize={setQueryPageSize}
            rowClick={rowClick}
            useTableSpinner={useTableSpinner}
        ></TableContainerServerSide>
    );
};
