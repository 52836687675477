import React, { FunctionComponent } from 'react';
import { Badge, Tooltip, Text, Stack } from '@chakra-ui/react';
import { Trans } from 'react-i18next';

export interface CBBadgeProps {
    colorScheme: string;
    description: string;
    hoverText?: string[];
    hoverColor?: string;
    hoverBgColor?: string;
}

export const CBBadge: FunctionComponent<CBBadgeProps> = (props) => {
    const { colorScheme, description, hoverText, hoverColor, hoverBgColor } = props;
    return (
        <Tooltip
            label={hoverText?.map((text) => (
                <Text
                    color={hoverColor}
                    fontSize={'var(--chakra-fontSizes-xs)'}
                    fontWeight={'var(--chakra-fontWeights-bold)'}
                    textAlign={'right'}
                >
                    {text}
                </Text>
            ))}
            aria-label={hoverText?.join(' ')}
            placement="top"
            hasArrow
            isDisabled={!hoverText}
            bgColor={hoverBgColor}
            padding={5}
            minWidth={200}
            maxWidth={800}
        >
            <Badge mr="1" colorScheme={colorScheme} variant="subtle">
                <Trans>{description}</Trans>
            </Badge>
        </Tooltip>
    );
};
