import React, { FunctionComponent } from 'react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Text } from '../Text';

interface Item {
    label: string;
    href?: string;
}

interface Props {
    items: Item[];
}

export const Breadcrumbs: FunctionComponent<Props> = (props: Props) => {
    const { items } = props;

    return (
        <Breadcrumb spacing={3} separator={<ChevronRightIcon color="color6" />}>
            {items.map((item: Item, index: number) => {
                const currentPage = index === items.length - 1;
                return (
                    <BreadcrumbItem key={index} isCurrentPage={currentPage}>
                        <BreadcrumbLink
                            href={item.href}
                            borderBottom="1px solid transparent"
                            _hover={{
                                textDecoration: 'none',
                                borderColor: currentPage ? 'transparent' : 'color6',
                                cursor: currentPage ? 'default' : 'pointer',
                            }}
                        >
                            <Text color={currentPage ? 'color10' : 'color6'}>{item.label}</Text>
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                );
            })}
        </Breadcrumb>
    );
};
