import React, { FunctionComponent } from 'react';
import { CustomerDataError } from '../../generated/types';
import { CBBadge } from './CBBadge';
import { CBCustomerBadge } from './CBCustomerBadge';

export interface CBCustomerDataErrorBadgeProps {
    data: CustomerDataError[];
}

export const CBCustomerDataErrorBadge: FunctionComponent<CBCustomerDataErrorBadgeProps> = (props) => {
    const { data } = props;
    if (data.length > 0) {
        return <CBCustomerBadge dataErrors={data} />;
    }
    return <CBBadge description="EverythingFine" colorScheme="green" />;
};
