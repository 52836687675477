import { isCodie } from '../../appBrand';
import { Config } from '../../content_builder_inferface';
import { Formatter } from '../../helper/formatter';

const codieExternalLink = {
    externalLink: {
        baseUrl: 'https://beraterportal.app.fp-finanzpartner.de/beraterportal/VermFormBT.aspx?id=',
        key: 'foreignId',
        title: 'CODie',
    },
};

export const config: Config = {
    advisor: [
        {
            as: 'CBProfileCardLarge',
            data: 'self',
            profileCardLarge: {
                nameKeys: ['firstName', 'lastName'],
                detailKeys: ['email', 'mobilePhone', 'faxPhone', 'displayAddress'],
                ...(isCodie ? codieExternalLink : {}),
            },
        },
        {
            as: 'Heading',
            content: 'personalInformation',
            props: {
                mt: 10,
                mb: 5,
                variant: 'heading',
            },
        },
        {
            as: 'CBDetails',
            data: 'customer',
            details: {
                detail_keys: [
                    'fullName',
                    'email',
                    'mobilePhone',
                    'homePhone',
                    'faxPhone',
                    'birthday',
                    'displayAddress',
                ],
                format: { birthday: { type: Formatter.DATE, format: 'DD.MM.YYYY' } },
            },
        },
    ],
    advisorCompany: [
        {
            as: 'CBProfileCardLarge',
            data: 'self',
            profileCardLarge: {
                nameKeys: ['company'],
                detailKeys: ['companyEmail', 'companyHomePhone', 'companyFaxPhone', 'displayAddress'],
                ...(isCodie ? codieExternalLink : {}),
            },
        },
        {
            as: 'Heading',
            content: 'personalInformation',
            props: {
                mt: 10,
                mb: 5,
                variant: 'heading',
            },
        },
        {
            as: 'CBDetails',
            data: 'customer',
            details: {
                detail_keys: [
                    'company',
                    'companyEmail',
                    'companyMobilePhone',
                    'companyHomePhone',
                    'companyFaxPhone',
                    'birthday',
                    'displayAddress',
                ],
                format: { birthday: { type: Formatter.DATE, format: 'DD.MM.YYYY' } },
            },
        },
    ],
};
