import { Icon } from '@chakra-ui/react';
import React, { FunctionComponent } from 'react';
import { IoCheckmarkCircleOutline, IoCloseCircleOutline } from 'react-icons/io5';
import { YesNoIcon } from '../../content_builder_inferface/yesnoicon';
import { CircleIcon } from '../CircleIcon';

export interface Props {
    data: boolean;
    yesNoIcon?: YesNoIcon;
}

export const CBYesNoIcon: FunctionComponent<Props> = (props) => {
    const { data, yesNoIcon } = props;
    const { asBobble = false, isInverted = false } = yesNoIcon ?? {};
    const value = isInverted ? !data : data;

    if (asBobble) {
        return value ? <CircleIcon color="color4" w={3} h={3} /> : null;
    } else {
        const color = value ? 'green.500' : 'red.500';
        const icon = value ? IoCheckmarkCircleOutline : IoCloseCircleOutline;

        return <Icon as={icon} color={color} w={6} h={6} />;
    }
};
