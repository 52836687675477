import React, { FunctionComponent } from 'react';
import {
    DashboardCustomerWithState,
    InvitationState,
    useDashboardSendCodeToCustomerMutation,
} from '../../generated/types';

import {
    Button,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Input,
    useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';
import { Divider } from '@chakra-ui/react';

interface CBVideoIdentButtonProps {
    data: DashboardCustomerWithState;
}
interface AusweisCheckReturn {
    result: boolean;
    type?: string;
    ausweisnummer?: string;
    nation?: string;
    error?: string;
}
class AusweisCheck {
    nummer: string;
    alphas: Record<string, number>;
    constructor(ausweisnummer: string) {
        this.nummer = ausweisnummer.toUpperCase();
        this.alphas = {
            '0': 0,
            '1': 1,
            '2': 2,
            '3': 3,
            '4': 4,
            '5': 5,
            '6': 6,
            '7': 7,
            '8': 8,
            '9': 9,
            A: 10,
            B: 11,
            C: 12,
            D: 13,
            E: 14,
            F: 15,
            G: 16,
            H: 17,
            I: 18,
            J: 19,
            K: 20,
            L: 21,
            M: 22,
            N: 23,
            O: 24,
            P: 25,
            Q: 26,
            R: 27,
            S: 28,
            T: 29,
            U: 30,
            V: 31,
            W: 32,
            X: 33,
            Y: 34,
            Z: 35,
        };
    }

    get checkPerso(): AusweisCheckReturn {
        // Wenn eingegebene Personummer weniger als 10 Zeichen hat abbrechen
        if (this.nummer.length < 10) {
            return { result: false };
        }

        const prufziffer = parseInt(this.nummer.substr(9), 10);
        const personummer = this.nummer.substr(0, 9);
        const arr: string[] = personummer.split('');
        let iter = 7;
        const arr_zahlen = [];
        let endziffern = 0;

        // Jedes  Zeichen mit der dazugehörigen Zahl ersetzten und multiplizieren
        for (let i = 0; i < arr.length; i++) {
            const curchar: string = arr[i];
            if (iter == 7) {
                const result = this.alphas[curchar] * iter;
                arr_zahlen.push(result);
                iter = 3;
            } else if (iter == 3) {
                const result = this.alphas[curchar] * iter;
                arr_zahlen.push(result);
                iter = 1;
            } else if (iter == 1) {
                const result = this.alphas[curchar] * iter;
                arr_zahlen.push(result);
                iter = 7;
            }
        }

        // Die letzten Stellen der einzelnen Ergebnisse addieren
        for (let i = 0; i < 9; i++) {
            const val = String(arr_zahlen[i]);
            const temp = val.substr(val.length - 1);
            endziffern += parseInt(temp);
        }

        const ende = endziffern % 10;
        if (ende == prufziffer) {
            const resarr: AusweisCheckReturn = { result: true, ausweisnummer: personummer, type: 'Personalausweis' };
            return resarr;
        } else {
            const resarr: AusweisCheckReturn = { result: false, error: 'Prüfsumme stimmt nicht überein' };
            return resarr;
        }
    }

    get checkReisepass(): AusweisCheckReturn {
        // Wenn eingegebene Personummer weniger als 10 Zeichen hat abbrechen
        if (this.nummer.length < 11) {
            return { result: false };
        }

        const passnummer = this.nummer.substring(0, 9);
        const prufziffer = parseInt(this.nummer.charAt(9), 10);
        const nation = this.nummer.substring(10);
        const arr = passnummer.split('');
        let iter = 7;
        const arr_zahlen = [];
        let endziffern = 0;

        // Jedes  Zeichen mit der dazugehörigen Zahl ersetzten und multiplizieren
        for (let i = 0; i < arr.length; i++) {
            const curchar: string = arr[i];
            if (iter == 7) {
                const result = this.alphas[curchar] * iter;
                arr_zahlen.push(result);
                iter = 3;
            } else if (iter == 3) {
                const result = this.alphas[curchar] * iter;
                arr_zahlen.push(result);
                iter = 1;
            } else if (iter == 1) {
                const result = this.alphas[curchar] * iter;
                arr_zahlen.push(result);
                iter = 7;
            }
        }

        // Die letzten Stellen der einzelnen Ergebnisse addieren
        for (let i = 0; i < 9; i++) {
            const val = String(arr_zahlen[i]);
            const temp = val.substr(val.length - 1);
            endziffern += parseInt(temp);
        }

        const ende = endziffern % 10;
        if (ende == prufziffer) {
            const resarr: AusweisCheckReturn = {
                result: true,
                ausweisnummer: passnummer,
                type: 'Reisepass',
                nation: nation,
            };
            return resarr;
        } else {
            const resarr: AusweisCheckReturn = { result: false, error: 'Prüfsumme stimmt nicht überein' };
            return resarr;
        }
    }
}

export const CBVideoIdentButton: FunctionComponent<CBVideoIdentButtonProps> = (props) => {
    const customer = props.data;
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [persoDataInvalid, setPersoDataInvalid] = React.useState(true);
    const [persoResult, setPersoResult] = React.useState('');
    const [persoDataInput, setPersoDataInput] = React.useState('');
    const [mailSending, setMailSending] = React.useState(false);
    const [mailSent, setMailSent] = React.useState(false);
    const [userCode] = React.useState(Math.floor(100000 + Math.random() * 900000));
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const handlePersoDataChange = (event: any) => {
        checkPerso(event.target.value);
    };
    const [, executeSendCodeToCustomer] = useDashboardSendCodeToCustomerMutation();
    const checkPerso = (persoData: string) => {
        const check = new AusweisCheck(persoData);
        const checkPerso = check.checkPerso;
        const checkReisepass = check.checkReisepass;
        if (!checkPerso && !checkReisepass) {
            setPersoDataInvalid(true);
        } else {
            if ((checkPerso && checkPerso.result) || (checkReisepass && checkReisepass.result)) {
                setPersoDataInvalid(false);
                if (checkPerso && checkPerso.result) {
                    setPersoResult('Gültige Personalausweisnummer erkannt.');
                } else if (checkReisepass && checkReisepass.result) {
                    setPersoResult('Gültige Reisepassnummer erkannt.');
                }
            } else {
                setPersoDataInvalid(true);
            }
        }
        setPersoDataInput(persoData);
    };
    const sendCode = async () => {
        setMailSending(true);
        const result = await executeSendCodeToCustomer({
            foreignId: customer.foreignId,
            userCode: userCode.toString(),
        });
        if (result) {
            console.log(result);
        }
        setTimeout(() => {
            setMailSent(true);
            setMailSending(false);
        }, 3000);
    };

    if (customer.dataErrors?.length === 0 && customer.invitationState === InvitationState.Uninvited) {
        return (
            <>
                <Button variant={'submit'} onClick={onOpen}>
                    {t('videoident')}
                </Button>
                <Modal onClose={onClose} size={'xl'} isOpen={isOpen}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Video Ident Prozess</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <FormControl>
                                <FormLabel>Ausweis/Reisepassnummer min. 10 Stellen</FormLabel>
                                <Input
                                    value={persoDataInput}
                                    onChange={handlePersoDataChange}
                                    isInvalid={persoDataInvalid}
                                    errorBorderColor="crimson"
                                    focusBorderColor={persoDataInvalid ? 'crimson' : 'green.300'}
                                    maxLength={15}
                                    readOnly={!persoDataInvalid}
                                />
                                {persoDataInvalid ? (
                                    <FormHelperText color={'crimson'}>
                                        <span style={{ fontWeight: 'bold' }}>
                                            Bitte geben Sie die Ausweis- oder Reisepassnummer ein. Sie finden diese auf
                                            Rückseite des Ausweises.
                                        </span>
                                    </FormHelperText>
                                ) : (
                                    <FormHelperText color={'green.300'}>
                                        <span style={{ fontWeight: 'bold' }}>{persoResult}</span>
                                    </FormHelperText>
                                )}
                            </FormControl>
                            {!persoDataInvalid ? (
                                <>
                                    <Divider mt={6} mb={6} />
                                    <FormControl>
                                        <FormLabel mb={10}>
                                            Der Code <span style={{ fontWeight: 'bold' }}>{userCode}</span> Code wird
                                            dem Kunden <span style={{ fontWeight: 'bold' }}>{customer.fullName}</span>{' '}
                                            per E-Mail an <span style={{ fontWeight: 'bold' }}>{customer.email}</span>{' '}
                                            gesendet.
                                        </FormLabel>

                                        <Button variant={'submit'} onClick={sendCode} isLoading={mailSending}>
                                            Code {userCode} {mailSent ? 'erneut' : ''} per E-Mail senden
                                        </Button>
                                        <FormLabel mt={10}>
                                            Der Kunde muss <span style={{ fontWeight: 'bold' }}>Ihnen</span> diesen Code
                                            dann via Teams mitteilen. Wenn der Code übereinstimmt, ist die E-Mail
                                            Adresse verifiziert.
                                        </FormLabel>
                                    </FormControl>
                                </>
                            ) : (
                                ''
                            )}
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={onClose}>
                                Verlassen
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        );
    }
    return null;
};
