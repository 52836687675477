import React, { FunctionComponent } from 'react';
import { InvitationState, CustomerDataError, DashboardCustomerStats } from '../../generated/types';
import { customerBadges } from '../../helper/customerState';
import { CBBadge } from './CBBadge';

export interface CBCustomerBadgeProps {
    invitationState?: InvitationState;
    dataErrors?: CustomerDataError[];
    stats?: DashboardCustomerStats;
}

export const CBCustomerBadge: FunctionComponent<CBCustomerBadgeProps> = (props) => {
    const { invitationState, dataErrors, stats } = props;
    const badges = customerBadges(invitationState, dataErrors, stats);
    return (
        <>
            {badges.length > 0
                ? badges.map((badge, i) => (
                      <CBBadge
                          key={'badge' + i}
                          colorScheme={badge.colorScheme}
                          description={badge.description}
                          hoverText={badge.hoverText}
                          hoverColor={badge.hoverColor}
                          hoverBgColor={badge.hoverBgColor}
                      />
                  ))
                : null}
        </>
    );
};
