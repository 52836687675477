import { Config } from '../../content_builder_inferface';
import { config as InviteCustomerDetails, CustomerDetailsTabs } from './inviteCustomerDetails';

export const config: Config = {
    customer: InviteCustomerDetails.customer,
    tabs: [
        {
            as: 'CBTab',
            tab: {
                defaultTabIndexMethod: 'defaultTabIndexMethod',
                elements: [
                    ...CustomerDetailsTabs,
                    {
                        name: 'appSettings',
                        accessRoles: ['walt_admin', 'walt_supervisor'],
                        component: [
                            {
                                as: 'CBDevices',
                                data: 'devices',
                            },
                            {
                                as: 'CBEntitlement',
                                data: 'entitlements',
                            },
                        ],
                    },
                ],
            },
        },
    ],
    selectedThread: InviteCustomerDetails.selectedThread,
};
