import React, { FunctionComponent } from 'react';
import parse from 'html-react-parser';
import { Field, Formik } from 'formik';
import {
    FormControl,
    HStack,
    Spacer,
    VStack,
    Input,
    Checkbox,
    Box,
    Center,
    FormLabel,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Divider,
    RadioGroup,
    FormErrorMessage,
    Stack,
    Radio,
} from '@chakra-ui/react';
import { Button } from '../Button';
import { Template } from '../../content_builder_inferface/template';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { AgreementDocumentFilledField, AgreementProcessWithDataFields } from '../../generated/types';
import { Text } from '../Text';
import { colors } from '../../theme/colors';
import { Heading } from '../Heading';
import * as Yup from 'yup';
import { createYupSchema } from '../../utils/createYupSchema';

export interface EmailTemplateData {
    name: string;
    editable: boolean;
    content: string;
}

interface Props {
    data: {
        mailTemplateItems: EmailTemplateData[];
        agreementProcesses: AgreementProcessWithDataFields[];
    };
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    methods: { [key: string]: any };
    template: Template;
}

const splitter = '<||>';
const createId = (agreementProcessId: string, agreementDocumentFieldId: string): string => {
    return `${agreementProcessId}${splitter}${agreementDocumentFieldId}`;
};

export const CBMailTemplate: FunctionComponent<Props> = (props) => {
    const { data, methods, template } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();

    /** Initialize all field values by config and data. Else use empty string */
    const initialValues: { [key: string]: string } = {
        serviceFee: data.agreementProcesses.length > 0 ? data.agreementProcesses[0].agreementProcessId : '',
    };
    data.mailTemplateItems.forEach((field) => {
        initialValues[field.name] = prepareContent(field.content);
    });
    data.agreementProcesses.forEach((process) => {
        process.acceptenceDataFields?.forEach((field) => {
            initialValues[`${createId(process.agreementProcessId, field.agreementDocumentFieldId)}`] =
                field.default ?? '';
        });
    });

    /**
     * Define submit method for form
     * If defined method found in config call method with values
     * Else call console.log with values
     */
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const submit = (values: any) => {
        const result = Object.entries(values)
            .map(([key, value]) => {
                // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                const original: any = data.mailTemplateItems.find((item) => item.name === key);

                if (original && '__typename' in original) delete original['__typename'];

                if (original && !original.editable) {
                    return original;
                }

                if (key.includes(splitter) && !key.startsWith(values.serviceFee)) {
                    return original;
                }

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return {
                    ...original,
                    name: key,
                    content: typeof value === 'string' ? value.split('\n').join('<BR>') : value,
                };
            })
            .filter((item) => item !== undefined);
        if (template.submitButton.onClick in methods) {
            methods[template.submitButton.onClick](result);
        } else {
            console.log(result);
        }
    };

    const cancel = () => {
        navigate(-1);
    };

    const readonly = (): boolean => {
        const _readonly = template.readonly;
        if (_readonly && _readonly in methods) return methods[_readonly]();
        return false;
    };

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const yupConfig: any[] = [];

    // create YUP config
    data.agreementProcesses.forEach((process) => {
        process.acceptenceDataFields?.forEach((field) => {
            if (field.validation) {
                const validations = [];
                if (field.mandatory) validations.push({ type: 'required', params: [t('validationErrorRequired')] });
                if (field.validation.min)
                    validations.push({
                        type: 'min',
                        params: [field.validation.min, t('validationErrorNumberMin', { value: field.validation.min })],
                    });
                if (field.validation.max)
                    validations.push({
                        type: 'max',
                        params: [field.validation.max, t('validationErrorNumberMax', { value: field.validation.max })],
                    });
                if (field.dataType === 'Number')
                    validations.push({ type: 'integer', params: [t('validationErrorNumberInteger')] });

                yupConfig.push({
                    id: createId(process.agreementProcessId, field.agreementDocumentFieldId),
                    label: field.dashboardLabel,
                    type: field.dataType === 'Number' ? 'number' : 'text',
                    validationType: field.dataType === 'Number' ? 'number' : 'string',
                    required: true,
                    value: undefined,
                    validations: validations,
                });
            }
        });
    });

    let schemas = {};
    yupConfig.forEach((element) => {
        schemas = createYupSchema(schemas, element);
    });

    const validationSchema = Yup.object().shape(schemas);

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
            {({ handleSubmit, errors, touched, values }) => (
                <form onSubmit={handleSubmit}>
                    <VStack spacing={16} align="flex-start">
                        {data.agreementProcesses && (
                            <Accordion
                                defaultIndex={[0]}
                                width="100%"
                                borderX={`1px solid ${colors.color1}`}
                                borderRadius={6}
                                allowToggle
                            >
                                <AccordionItem key="prozess">
                                    <AccordionButton borderBottom={`1px solid ${colors.color1}`}>
                                        <Text flex="1" textAlign="left" variant="tabLabel">
                                            process
                                        </Text>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel pb={4}>
                                        <FormControl key={'someKey'} isInvalid={false} isRequired={true}>
                                            <RadioGroup value={values.serviceFee} name="serviceFee">
                                                <Stack spacing={5} direction="column">
                                                    {data.agreementProcesses.map((process, index) => {
                                                        return (
                                                            <Box key={`${index}_box`}>
                                                                <Field
                                                                    key={index}
                                                                    alignItems="baseline"
                                                                    name="serviceFee"
                                                                    as={Radio}
                                                                    type="radio"
                                                                    value={process.agreementProcessId}
                                                                >
                                                                    <Box pl={4}>
                                                                        <Heading variant="heading">
                                                                            {process.name}
                                                                        </Heading>
                                                                        <Text>{process.description}</Text>
                                                                    </Box>
                                                                </Field>
                                                                <Box
                                                                    pl={10}
                                                                    hidden={
                                                                        values.serviceFee !== process.agreementProcessId
                                                                    }
                                                                >
                                                                    {process.acceptenceDataFields
                                                                        ?.sort((a, b) => {
                                                                            if (a.order && b.order) {
                                                                                return a.order - b.order;
                                                                            }
                                                                            return 1;
                                                                        })
                                                                        .map((field, index) => {
                                                                            const length =
                                                                                process.acceptenceDataFields?.length;
                                                                            return (
                                                                                <>
                                                                                    {createDataField(
                                                                                        process,
                                                                                        field,
                                                                                        values,
                                                                                        errors,
                                                                                        touched,
                                                                                        readonly,
                                                                                        template,
                                                                                    )}
                                                                                    {length && index < length - 1 && (
                                                                                        <Divider mb={4} />
                                                                                    )}
                                                                                </>
                                                                            );
                                                                        })}
                                                                </Box>
                                                            </Box>
                                                        );
                                                    })}
                                                </Stack>
                                            </RadioGroup>
                                        </FormControl>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        )}
                        {/* <Accordion width="100%" borderX={`1px solid ${colors.color1}`} borderRadius={6} allowToggle>
                            <AccordionItem key={'inviteText'}>
                                <AccordionButton borderBottom={`1px solid ${colors.color1}`}>
                                    <Text flex="1" textAlign="left" variant="tabLabel">
                                        invitationPanelText
                                    </Text>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    <Divider my={4} />
                                    {data.mailTemplateItems
                                        .filter((field) => !field.name.includes(splitter))
                                        .map((field, index) => {
                                            if (!field.editable) {
                                                return (
                                                    <Box width={'100%'} key={index}>
                                                        <FormLabel htmlFor={field.name}>
                                                            <Trans>{field.name}</Trans>
                                                        </FormLabel>
                                                        <Center
                                                            width={'100%'}
                                                            border="1px"
                                                            borderRadius={6}
                                                            borderColor="gray.200"
                                                            p={4}
                                                        >
                                                            <Box>{parse(field.content)}</Box>
                                                        </Center>
                                                    </Box>
                                                );
                                            }

                                            return (
                                                <Box width={'100%'} key={index}>
                                                    <FormLabel htmlFor={field.name}>
                                                        <Trans>{field.name}</Trans>
                                                    </FormLabel>
                                                    <Box
                                                        width={'100%'}
                                                        border="1px"
                                                        borderRadius={6}
                                                        borderColor="gray.200"
                                                        px={2}
                                                        py={1}
                                                    >
                                                        {parse(field.content)}
                                                    </Box>
                                                </Box>
                                            );
                                        })}
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion> */}

                        {!readonly() && (
                            <HStack width="100%">
                                <Spacer />
                                {template.additionalButtons.map((button) => {
                                    let clickMethod = methods[button.onClick];
                                    if (!clickMethod && button.onClick === 'cancel') clickMethod = cancel;
                                    return (
                                        <Button key={button.label} variant="outline" onClick={clickMethod}>
                                            {button.label}
                                        </Button>
                                    );
                                })}
                                {
                                    <Button key={template.submitButton.label} variant="submit" type="submit">
                                        {template.submitButton.label}
                                    </Button>
                                }
                            </HStack>
                        )}
                    </VStack>
                </form>
            )}
        </Formik>
    );
};

const prepareContent = (content: string): string => {
    return typeof content === 'string' ? content.split('<BR>').join('\n') : content;
};

const createDataField = (
    process: AgreementProcessWithDataFields,
    field: AgreementDocumentFilledField,
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    values: any,
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    errors: any,
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    touched: any,
    readonly: () => boolean,
    template: Template,
) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const _id = createId(process.agreementProcessId, field.agreementDocumentFieldId);
    const _isSelectedRadio = values.serviceFee === process.agreementProcessId;
    const _readonly = !_isSelectedRadio || readonly() || field.readonly;
    const _isNumber = field.dataType === 'Number';
    const _isBoolean = field.dataType === 'Boolean';
    const _field = _isBoolean ? Checkbox : Input;
    return (
        field.dashboardLabel && (
            <FormControl
                key={_id}
                isInvalid={!!errors[_id] && touched[_id]}
                isRequired={field.mandatory && _isSelectedRadio}
                mb={4}
            >
                <Stack direction={_isBoolean ? 'row' : 'column'} {...(_isBoolean && { flexDirection: 'row-reverse' })}>
                    <FormLabel htmlFor={_id} width={'100%'} {...(_isBoolean && { pl: 2 })} mb={0}>
                        <Trans>
                            {field.dashboardLabel} ({field.dashboardDescription})
                        </Trans>
                    </FormLabel>
                    <Field
                        as={_field}
                        id={_id}
                        name={_id}
                        type={_isNumber ? 'number' : 'text'}
                        rows={template.rows ?? 5}
                        readOnly={_readonly}
                        variant={_readonly ? 'outline' : 'filled'}
                        defaultChecked={field.default === 'true' ? true : undefined}
                    ></Field>
                </Stack>
                {/**  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                 * @ts-ignore */}
                <FormErrorMessage>{errors[`${_id}`]}</FormErrorMessage>
            </FormControl>
        )
    );
};
