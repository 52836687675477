import React, { FunctionComponent } from 'react';
import { ContentBuilder } from '../../components/ContentBuilder';
import { ContentContainer } from '../../components/ContentContainer';
import { config } from '../../content_builder_config/advisors/list';
import { useDashboardAllAdvisorsQuery } from '../../generated/types';
import LoadingIndicator from '../../components/LoadingIndicator';
import ErrorBox from '../../components/ErrorBox';

interface ListAdvisors {
    advisorId: string;
    fullName: string;
    email: string | string[];
    mobilePhone: string;
    faxPhone: string;
    displayAddress: string;
}

export const List: FunctionComponent = () => {
    const [resultDashboardAllAdvisorsQuery] = useDashboardAllAdvisorsQuery();
    const { data, fetching, error } = resultDashboardAllAdvisorsQuery;

    if (fetching) return <LoadingIndicator />;
    if (error) return <ErrorBox message={error.message} />;

    let advisors: ListAdvisors[] = [];
    if (data?.dashboardAllAdvisors) {
        advisors = data?.dashboardAllAdvisors.map((item) => {
            return {
                advisorId: item.advisorId,
                fullName: item.isCompany ? item.company ?? '' : item.fullName ?? '',
                email: item.isCompany ? item.companyEmail ?? '' : item.email ?? '',
                faxPhone: item.isCompany ? item.companyFaxPhone ?? '' : item.faxPhone ?? '',
                mobilePhone: item.isCompany ? item.companyMobilePhone ?? '' : item.mobilePhone ?? '',
                displayAddress: item.displayAddress ?? '',
            };
        });
    }

    return (
        <ContentContainer headline="advisors" description="advisorsDescription">
            <ContentBuilder config={config.table} data={{ advisors }} />
        </ContentContainer>
    );
};
