// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FunctionComponent } from 'react';
import { useCallback } from 'react';
// import { Box, Center, Container, Stack } from '@chakra-ui/react';
import { useKeycloak } from '@react-keycloak/web';
// import { Heading } from '../components/Heading';
// import { Button } from '../components/Button';
// import { Logo } from '../components/Logo';

export const Login: FunctionComponent = () => {
    const { keycloak } = useKeycloak();

    const login = useCallback(async () => {
        await keycloak?.login();
    }, [keycloak]);

    if (!keycloak?.authenticated) {
        login();
    }

    return null;

    // We want a direct Login so this is commented out
    // return (
    //     <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
    //         <Stack spacing="8" mt={8}>
    //             <Center>
    //                 <Logo width={300} />
    //             </Center>
    //             <Box
    //                 py={{ base: '0', sm: '8' }}
    //                 px={{ base: '4', sm: '10' }}
    //                 bg="bg-surface"
    //                 boxShadow={{ base: 'none', sm: 'md' }}
    //                 borderRadius={{ base: 'none', sm: 'xl' }}
    //             >
    //                 <Stack spacing="6">
    //                     <Center>
    //                         <Heading variant="title">loginHeadline</Heading>
    //                     </Center>
    //                     <Button variant="submit" onClick={() => login()}>
    //                         login
    //                     </Button>
    //                 </Stack>
    //             </Box>
    //         </Stack>
    //     </Container>
    // );
};
