import React, { FunctionComponent, ReactNode } from 'react';
import { Heading as ChakraHeading, HeadingProps } from '@chakra-ui/react';
import { Trans } from 'react-i18next';

interface Props extends HeadingProps {
    children: string | number | ReactNode | ReactNode[];
}

export const Heading: FunctionComponent<Props> = (props) => {
    const { children, ...rest } = props;
    return (
        <ChakraHeading {...rest}>
            {typeof children !== 'string' && typeof children === 'number' ? children : <Trans>{children}</Trans>}
        </ChakraHeading>
    );
};
