import React, { FunctionComponent } from 'react';
import { HStack, Icon, Link, LinkProps } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { IoLogOutOutline } from 'react-icons/io5';
import { Text } from '../Text';
// import { useRouteMatch } from 'react-router-dom';

interface NavLinkProps extends LinkProps {
    isActive?: boolean;
    label: string;
    icon: IconType;
    to?: string;
}

export const NavLink: FunctionComponent<NavLinkProps> = (props: NavLinkProps) => {
    const { icon, label, ...rest } = props;
    // const isActive = rest.to ? useRouteMatch(rest.to)?.isExact : false;
    const isActive = false;

    return (
        <Link
            display="block"
            py="1"
            px="2"
            borderRadius="md"
            transition="all 0.3s"
            fontSize="sm"
            userSelect="none"
            color="color10"
            aria-current={isActive ? 'page' : undefined}
            _hover={{
                bg: 'color3',
                textDecoration: 'none',
            }}
            _activeLink={{
                bg: 'color1',
                color: 'inherit',
            }}
            {...rest}
        >
            <HStack spacing="2">
                <Icon as={icon} fontSize="lg" opacity={0.64} />
                <Text as="span" flex="1">
                    {label}
                </Text>
                {rest.isExternal && <IoLogOutOutline />}
            </HStack>
        </Link>
    );
};
