import React, { FunctionComponent } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CircleIcon: FunctionComponent<IconProps> = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 200 200" {...props}>
            <path fill="currentColor" d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0" />
        </Icon>
    );
};
