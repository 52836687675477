import React, { FunctionComponent } from 'react';
import { DashboardCustomerStats, DashboardCustomerWithState, InvitationState } from '../../generated/types';
import { CBCustomerBadge } from './CBCustomerBadge';

export interface CBCustomerInvitationStateBadge {
    data: InvitationState | DashboardCustomerWithState;
    stats: DashboardCustomerStats;
}

export const CBCustomerInvitationStateBadge: FunctionComponent<CBCustomerInvitationStateBadge> = (props) => {
    const { data, stats } = props;
    if (typeof data === 'string') {
        const invitationState = data as InvitationState;
        return <CBCustomerBadge invitationState={invitationState} stats={stats} />;
    }

    const customerWithState = data as DashboardCustomerWithState;

    return (
        <CBCustomerBadge
            invitationState={customerWithState.invitationState ?? undefined}
            stats={customerWithState.stats ?? undefined}
        />
    );
};
