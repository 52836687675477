import React, { FunctionComponent } from 'react';
import { Box, FormControl, FormLabel, Textarea, SimpleGrid, FormErrorMessage, useToast } from '@chakra-ui/react';
import { Formik, Field } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { entitlements } from '../../Entitlements';
import { useDashboardSaveCustomerSettingsMutation } from '../../generated/types';
import { errorFeedback, successFeedback } from '../../helper/feedback';
import { AlignRight } from '../AlignRight';
import { Button } from '../Button';
import { Heading } from '../Heading';
import { Text } from '../Text';

interface Props {
    data: {
        foreignId: string;
        entitlement?: string;
    };
}

const outputJSON = (data: { [key: string]: string }) => {
    return JSON.stringify(data, null, 4);
};

export const CBEntitlement: FunctionComponent<Props> = (props) => {
    const { foreignId, entitlement } = props.data;

    const initialValues = {
        entitlement: !entitlement ? '' : outputJSON(JSON.parse(entitlement)),
    };
    const { t } = useTranslation();
    const toast = useToast();

    const [_state, executeSaveEntitlement] = useDashboardSaveCustomerSettingsMutation();

    const submit = async (values: { entitlement: string }) => {
        const result = await executeSaveEntitlement({
            entitlements: outputJSON(JSON.parse(values.entitlement)),
            foreignId,
        });

        const { error } = result;
        if (error) {
            errorFeedback(toast, error.message);
        } else {
            successFeedback(toast, t('entitlementSaved'));
        }
    };

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const updateTextarea = (key: string, value: any, field: string) => {
        try {
            const parsed = field.trim() === '' ? {} : JSON.parse(field);
            if (key in parsed) delete parsed[key];
            else parsed[key] = value;

            return outputJSON(parsed);
        } catch (_error) {
            errorFeedback(toast, t('invalidJSON'));
        }
        return field;
    };

    return (
        <>
            <Formik initialValues={initialValues} onSubmit={submit}>
                {({ handleSubmit, errors, touched, values, setFieldValue }) => (
                    <>
                        <Box mb={4}>
                            {entitlements.map((headline) => {
                                return (
                                    <Heading key={headline.label} as="h4" size="ms">
                                        <>
                                            {headline.label}
                                            <SimpleGrid columns={4} py={4} spacing={4}>
                                                {headline.items.map((item) => (
                                                    <Button
                                                        key={item.label}
                                                        onClick={() =>
                                                            setFieldValue(
                                                                'entitlement',
                                                                updateTextarea(
                                                                    item.key,
                                                                    item.value,
                                                                    values.entitlement,
                                                                ),
                                                            )
                                                        }
                                                    >
                                                        <Text style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                            {item.label}
                                                        </Text>
                                                    </Button>
                                                ))}
                                            </SimpleGrid>
                                        </>
                                    </Heading>
                                );
                            })}
                        </Box>
                        <form onSubmit={handleSubmit}>
                            <FormControl
                                key="entitlement"
                                isInvalid={!!errors.entitlement && touched.entitlement}
                                isRequired
                            >
                                <FormLabel htmlFor="entitlement">
                                    <Trans>entitlement</Trans>
                                </FormLabel>
                                <Field
                                    as={Textarea}
                                    id="entitlement"
                                    name="entitlement"
                                    type={'text'}
                                    rows={10}
                                    variant="filled"
                                    validate={(value: string) => {
                                        try {
                                            JSON.parse(value);
                                        } catch (_error) {
                                            return t('invalidJSON');
                                        }
                                    }}
                                />
                                <FormErrorMessage>{errors.entitlement}</FormErrorMessage>
                            </FormControl>
                            <AlignRight pt={4}>
                                <Button variant="submit" type="submit">
                                    {t('save')}
                                </Button>
                            </AlignRight>
                        </form>
                    </>
                )}
            </Formik>
        </>
    );
};
