import React, { FunctionComponent } from 'react';
import { Text } from '../Text';

interface ProfileCardProps {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    data: any;
}

export const CBText: FunctionComponent<ProfileCardProps> = (props) => {
    const { data } = props;

    return <Text>{data}</Text>;
};
