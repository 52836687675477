/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
    RawBasicFieldObject,
    ConvertedData,
    Field,
    RawData,
    RawField,
    ValueType,
    BasicFieldObject,
    FlatData,
    FieldType,
} from './Interface';
import moment from 'moment';
import i18next from './../i18n';

interface Config {
    [key: string]: Options;
}

interface Options {
    dateFormat?: 'DD.MM.YYYY' | 'DD.MM.YY';
    output?: 'toLocaleString';
    translationFields?: string[];
}

export const converter = <T>(rawData: RawData | RawData[], config?: Config, flat = true): T => {
    const _config = config ?? {};

    const isArray = Array.isArray(rawData);
    const _rawData: RawData[] = isArray ? rawData : [rawData];
    const result: ConvertedData[] = [];

    _rawData.forEach((element: RawData) => {
        const resultElement: ConvertedData = {
            fields: {},
        };
        Object.keys(element).forEach((key) => {
            if (key === 'fields') {
                element.fields.map((item: RawField) => {
                    resultElement.fields[item.fieldKey] = fieldConverter(item, _config);
                });
            } else if (key !== '__typename') {
                // @ts-ignore
                if (typeof element[key] !== 'object') {
                    // @ts-ignore
                    resultElement[key] = element[key];
                } else {
                    // @ts-ignore
                    if (element[key] && Array.isArray(element[key])) {
                        // @ts-ignore
                        element[key].forEach((subElement: RawBasicFieldObject) => {
                            const subResult: BasicFieldObject = {
                                fields: {},
                            };
                            subElement.fields.forEach((subItem: RawField) => {
                                subResult.fields[subItem.fieldKey] = fieldConverter(subItem, _config);
                            });
                            // @ts-ignore
                            if (key in resultElement) resultElement[key].push(subResult);
                            // @ts-ignore
                            else resultElement[key] = [subResult];
                        });
                    }
                }
            }
        });

        result.push(resultElement);
    });

    // Return instandly a flat design
    if (flat) {
        const _result = flatDataConverter(result);
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        return isArray ? (_result as any) : (_result[0] as any);
    }

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    return isArray ? (result as any) : (result[0] as any);
};

export const flatDataConverter = (data: ConvertedData[]): FlatData[] => {
    const result: FlatData[] = data.map((item) => {
        let _result: FlatData = {};
        Object.keys(item).forEach((rootKey) => {
            if (rootKey === 'fields') {
                _result = flatDataConverterElement(item[rootKey]);
            } else {
                // @ts-ignore
                if (typeof item[rootKey] !== 'object') {
                    // @ts-ignore
                    _result[rootKey] = item[rootKey];
                } else {
                    // @ts-ignore
                    _result[rootKey] = item[rootKey].map((subItem: BasicFieldObject) =>
                        flatDataConverterElement(subItem.fields),
                    );
                }
            }
        });
        return _result;
    });

    return result;
};

const flatDataConverterElement = (fields: { [key: string]: Field }): { [key: string]: string } => {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const result: any = {};
    Object.entries(fields).forEach(([key, value]) => {
        result[key] = value.value;
    });
    return result;
};

const fieldConverter = (rawField: RawField, config: Config): Field => {
    const result: Field = {
        key: rawField.fieldKey,
        type: rawField.valueType as FieldType,
        value: fieldValueConverter(rawField, config),
    };

    if (rawField.isEditable) result['isEditable'] = true;

    return result;
};

const fieldValueConverter = (rawField: RawField, config: Config): ValueType => {
    const _valueType: string = rawField.valueType;
    const option: Options | null = _valueType in config ? config[_valueType] : null;

    switch (rawField.valueType) {
        case 'date':
            const _dateValue = moment(rawField.dateString);
            if (option) return _dateValue.format(option.dateFormat ?? 'DD.MM.YYYY');
            return _dateValue;
        case 'flag':
            return !!rawField.flag;
        case 'picture':
            return {
                data: rawField.dataBase64 ?? '',
                type: rawField.string ?? 'jpeg',
            };
        case 'amount':
            const _amountValue = parseFloat(rawField.number ?? '');
            const _amountCurrency = rawField.string ?? 'EUR';
            if (option)
                return [
                    _amountValue.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                    _amountCurrency,
                ].join(' ');
            return {
                value: _amountValue,
                currency: _amountCurrency,
            };
        case 'number':
            const _numberValue = parseFloat(rawField.number ?? '');
            if (option) return _numberValue.toLocaleString(undefined, { maximumFractionDigits: 2 });
            return _numberValue;
        case 'phone':
        case 'email':
        case 'link':
        case 'address':
        case 'string':
        default:
            const value = rawField.string ?? '';
            if (option && option.translationFields?.includes(rawField.fieldKey)) {
                return i18next.t(value) as string;
            } else {
                return value;
            }
    }
};
