import { Box, Stack } from '@chakra-ui/react';
import React, { FunctionComponent } from 'react';
import { Text } from '../Text';
interface Props {
    label: string;
    children: React.ReactNode;
}

export const NavGroup: FunctionComponent<Props> = (props: Props) => {
    const { label, children } = props;
    return (
        <Box>
            <Text variant="uppercase" mb="2" mx="2">
                {label}
            </Text>
            <Stack spacing="1">{children}</Stack>
        </Box>
    );
};
