import moment from 'moment';
import { CBBadgeProps } from '../components/content_builder_components/CBBadge';
import { CustomerDataError, DashboardCustomerStats, InvitationState } from '../generated/types';

export const inviteStateColor = (state: InvitationState | null, asColorScheme = false): string => {
    switch (state) {
        case InvitationState.Appinuse:
            return asColorScheme ? 'green' : 'green.500';
        case InvitationState.Accepted:
            return asColorScheme ? 'blue' : 'blue.500';
        case InvitationState.Invited:
            return asColorScheme ? 'cyan' : 'cyan.500';
        case InvitationState.Uninvited:
            return asColorScheme ? 'yellow' : 'yellow.500';
        case InvitationState.Expired:
            return asColorScheme ? 'gray' : 'gray.500';
        default:
            return asColorScheme ? 'gray' : 'gray.500';
    }
};

interface InvitationStateHoverColorConfig {
    hoverColor: string;
    hoverBgColor: string;
}

export const invitationStateHoverColor = (state: InvitationState | null): InvitationStateHoverColorConfig => {
    switch (state) {
        case InvitationState.Appinuse:
            return { hoverColor: 'var(--chakra-colors-green-800)', hoverBgColor: 'var(--chakra-colors-green-100)' };
        case InvitationState.Accepted:
            return { hoverColor: 'var(--chakra-colors-blue-800)', hoverBgColor: 'var(--chakra-colors-blue-100)' };
        case InvitationState.Invited:
            return { hoverColor: 'var(--chakra-colors-cyan-800)', hoverBgColor: 'var(--chakra-colors-cyan-100)' };
        case InvitationState.Uninvited:
            return { hoverColor: 'var(--chakra-colors-yellow-800)', hoverBgColor: 'var(--chakra-colors-yellow-100)' };
        case InvitationState.Expired:
            return { hoverColor: 'var(--chakra-colors-gray-800)', hoverBgColor: 'var(--chakra-colors-gray-100)' };
        default:
            return { hoverColor: 'var(--chakra-colors-gray-800)', hoverBgColor: 'var(--chakra-colors-gray-100)' };
    }
};

const formatTimestamp = (timestamp: Date) => {
    const formatDate = 'DD.MM.YY';
    const formatTime = 'HH:mm';
    const formattedTimestamp = `${moment(timestamp).format(formatDate)} um ${moment(timestamp).format(formatTime)} Uhr`;
    return formattedTimestamp;
};

interface HoverTextWithTimestamp {
    hoverText: string;
    timestamp: Date;
}

export const customerBadges = (
    invitationState?: InvitationState,
    dataErrors?: CustomerDataError[],
    stats?: DashboardCustomerStats,
): CBBadgeProps[] => {
    const badgeProps: CBBadgeProps[] = [];
    if (invitationState) {
        let hoverText = undefined;
        const invitationStateHoverColorConfig = invitationStateHoverColor(invitationState);
        if (stats) {
            hoverText = new Array<string>();
            const hoverTextWithTimestamp = new Array<HoverTextWithTimestamp>();
            hoverTextWithTimestamp.push({
                hoverText: `${stats.automaticInvite ? 'automatische' : 'manuelle'} Einladung ${!stats.automaticInvite && stats.creator ? `durch ${stats.creator}` : ''} am ${formatTimestamp(stats.inviteTimestamp)}`,
                timestamp: stats.inviteTimestamp,
            });
            if (stats.appInstallTimestamp) {
                hoverTextWithTimestamp.push({
                    hoverText: `App installiert am ${formatTimestamp(stats.appInstallTimestamp)}`,
                    timestamp: stats.appInstallTimestamp,
                });
            }
            if (stats.lastUsageTimestamp) {
                hoverTextWithTimestamp.push({
                    hoverText: `letzte Nutzung am ${formatTimestamp(stats.lastUsageTimestamp)}`,
                    timestamp: stats.lastUsageTimestamp,
                });
            }
            if (stats.requestSignedTimestamp) {
                hoverTextWithTimestamp.push({
                    hoverText: `Vertrag akzeptiert am ${formatTimestamp(stats.requestSignedTimestamp)}`,
                    timestamp: stats.requestSignedTimestamp,
                });
            }
            if (stats.metaDataFirstTimestamp && stats.metaDataLastTimestamp) {
                hoverTextWithTimestamp.push({
                    hoverText: `Einrichtung am ${formatTimestamp(stats.metaDataFirstTimestamp)}`,
                    timestamp: stats.metaDataFirstTimestamp,
                });
                hoverTextWithTimestamp.push({
                    hoverText: `letzte Daten am ${formatTimestamp(stats.metaDataLastTimestamp)}`,
                    timestamp: stats.metaDataLastTimestamp,
                });
            } else {
                if (stats.requestSignedTimestamp) {
                    hoverTextWithTimestamp.push({ hoverText: `Keine Konten verbunden`, timestamp: new Date() });
                }
            }
            const sortedHoverTextWithTimestamp = hoverTextWithTimestamp.sort((a, b) =>
                moment(b.timestamp).diff(moment(a.timestamp)),
            );
            hoverText = sortedHoverTextWithTimestamp.map((element) => element.hoverText);
        }

        badgeProps.push({
            colorScheme: inviteStateColor(invitationState, true),
            description: invitationState,
            hoverText,
            ...invitationStateHoverColorConfig,
        });
    }
    dataErrors?.forEach((element) => {
        badgeProps.push({
            colorScheme: 'red',
            description: element.toString(),
        });
    });
    return badgeProps;
};
