import React from 'react';
import { Center, Flex } from '@chakra-ui/react';
import { MyRoutes } from './components/navigation/Routes';
import { useKeycloak } from '@react-keycloak/web';
import { Login } from './screen/Login';
import { Sidebar } from './components/navigation/Sidebar';
import LoadingIndicator from './components/LoadingIndicator';
import ErrorBox from './components/ErrorBox';
import * as Sentry from '@sentry/react';

const myFallback = <ErrorBox title="Es ist ein Fehler aufgetreten" message="Das Problem wurde gemeldet." />;

function App() {
    const { keycloak } = useKeycloak();

    return (
        <Sentry.ErrorBoundary fallback={myFallback} showDialog>
            {keycloak.authenticated ? (
                <Flex h="100vh" flex={1} flexDirection="row">
                    <Sidebar />
                    <Flex h="full" w="full" overflow="scroll">
                        <MyRoutes />
                    </Flex>
                </Flex>
            ) : (
                <Center h="70vh">{keycloak.authenticated === undefined ? <LoadingIndicator /> : <Login />}</Center>
            )}
        </Sentry.ErrorBoundary>
    );
}

export default App;
