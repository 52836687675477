export const entitlements = [
    {
        label: 'Support',
        items: [
            { label: 'GC Mandant Test (unused)', key: 'ground-control-mandant', value: 'FinanzpartnerTests' },
            {
                label: 'GC Mandant Experimental (unused)',
                key: 'ground-control-mandant',
                value: 'FinanzpartnerExperimental',
            },
            {
                label: 'GC Staging',
                key: 'ground-control-root-urls',
                value: ['https://gc.app.fp-finanzpartner.de/groundcontrol/V5-staging/'],
            },
            { label: 'Transaction ID', key: 'display-transactionid', value: true },
            { label: 'Finlytics Metadata', key: 'showFinlyticsMetaDataInTransactionDetails', value: true },
            { label: 'Balance Details', key: 'showBalanceDetails', value: true },
            { label: 'Reset Contract Inbox', key: 'showContractInboxReset', value: true },
            { label: 'Lock Screen Menu', key: 'lockViewDebugType', value: 'delete' },
            { label: 'Plaintext Password', key: 'shouldShowLockscreenPasswordAsTextAction', value: true },
            { label: 'Database Details', key: 'display-database-information', value: true },
            { label: 'Redirect Console', key: 'standard-logs-redirection-enabled', value: true },
            { label: 'Allow Screenshots', key: 'allowUnsecureWindow', value: true },
            { label: 'Display Folder Sizes', key: 'display-folder-sizes', value: true },
            { label: 'Crash Report Reset', key: 'allowCrashReportSettingReset', value: true },
            { label: 'Transaction JSON Export', key: 'jsonExportEnabled', value: true },
            { label: 'Category Management Helper', key: 'categoryExportAndReRunMigration', value: true },
            { label: 'Disable App (Banking)', key: 'userDisabled', value: true },
            { label: 'Deactivate App (User)', key: 'userCancelled', value: true },
        ],
    },
    {
        label: 'QA and Debug',
        items: [
            { label: 'Developer Menu', key: 'showDeveloperMenu', value: true },
            { label: 'Backgrounding Interval Accounts (Minutes)', key: 'backgrounding-refresh-interval', value: 10 },
            { label: 'Backgrounding Debug Logs', key: 'enable-beta-debugging2', value: true },
            { label: 'Financial Plan Reminder', key: 'financialPlanTestNotificationReminderDates', value: true },
            { label: 'Fincancial Plan Show Transactions', key: 'financialPlanShowTransactionEnabled', value: true },
            { label: 'Account Autorefresh Interval', key: 'autoRefresh-interval', value: 60 },
            { label: 'Debug Rating Popup', key: 'ratingPopupMinimumMinutes', value: 5 },
            { label: 'Weekly Notifications Debug', key: 'weeklyReportNotificationsDebugMode', value: true },
            { label: 'NSLogger', key: 'enableNSLogger', value: true },
            { label: 'Similar Transactions', key: 'similarTransactionsEnabled', value: true },
            { label: 'Watch Logs', key: 'showAppleWatchLogging', value: true },
            { label: 'Bank Parameters', key: 'showBankParameters', value: true },
            { label: 'Bank News Logo', key: 'bankNewsLogo', value: 'sparkasse' },
            { label: 'Bank News Bank ID', key: 'bankNewsBankID', value: '12030000' },
            { label: 'Help News Bank ID', key: 'helpNewsBankID', value: 'DE/Number26' },
            { label: 'Display Build Type', key: 'display-build-type', value: true },
            {
                label: 'Custom Colors',
                key: 'colors',
                value: {
                    'aluminium#light': 'FF00FFFF',
                    'aluminium#dark': '00FFFFFF',
                    'ash#light': 'FF00FFFF',
                    'ash#dark': '00FFFFFF',
                    'carrot#light': 'FF00FFFF',
                    'carrot#dark': '00FFFFFF',
                    'carrot_light#light': 'FF00FFFF',
                    'carrot_light#dark': '00FFFFFF',
                    'cloudy#light': 'FF00FFFF',
                    'cloudy#dark': '00FFFFFF',
                    'coal#light': 'FF00FFFF',
                    'coal#dark': '00FFFFFF',
                    'cobalt#light': 'FF00FFFF',
                    'cobalt#dark': '00FFFFFF',
                    'cobalt_dark#light': 'FF00FFFF',
                    'cobalt_dark#dark': '00FFFFFF',
                    'cobalt_extra_light#light': 'FF00FFFF',
                    'cobalt_extra_light#dark': '00FFFFFF',
                    'cobalt_light#light': 'FF00FFFF',
                    'cobalt_light#dark': '00FFFFFF',
                    'delete#light': 'FF00FFFF',
                    'delete#dark': '00FFFFFF',
                    'frosty#light': 'FF00FFFF',
                    'frosty#dark': '00FFFFFF',
                    'inverted_coal#light': 'FF00FFFF',
                    'inverted_coal#dark': '00FFFFFF',
                    'leafy#light': 'FF00FFFF',
                    'leafy#dark': '00FFFFFF',
                    'leafy_extra_light#light': 'FF00FFFF',
                    'leafy_extra_light#dark': '00FFFFFF',
                    'leafy_light#light': 'FF00FFFF',
                    'leafy_light#dark': '00FFFFFF',
                    'lemon#light': 'FF00FFFF',
                    'lemon#dark': '00FFFFFF',
                    'primary_color#light': 'FF00FFFF',
                    'primary_color#dark': '00FFFFFF',
                    'rust#light': 'FF00FFFF',
                    'rust#dark': '00FFFFFF',
                    'rust_extra_light#light': 'FF00FFFF',
                    'rust_extra_light#dark': '00FFFFFF',
                    'rust_light#light': 'FF00FFFF',
                    'rust_light#dark': '00FFFFFF',
                    'smoke#light': 'FF00FFFF',
                    'smoke#dark': '00FFFFFF',
                    'shadow#light': 'FF00FFFF',
                    'shadow#dark': '00FFFFFF',
                    'control_foreground#light': 'FF00FFFF',
                    'control_foreground#dark': '00FFFFFF',
                    'border#light': 'FF00FFFF',
                    'border#dark': '00FFFFFF',
                    'separator#light': 'FF00FFFF',
                    'separator#dark': '00FFFFFF',
                    'secondary_background#light': 'FF00FFFF',
                    'secondary_background#dark': '00FFFFFF',
                    'tertiary_background#light': 'FF00FFFF',
                    'tertiary_background#dark': '00FFFFFF',
                    'background#light': 'FF00FFFF',
                    'background#dark': '00FFFFFF',
                    'grouped_background#light': 'FF00FFFF',
                    'grouped_background#dark': '00FFFFFF',
                    'secondary_grouped_background#light': 'FF00FFFF',
                    'secondary_grouped_background#dark': '00FFFFFF',
                    'navigationbar_shadow#light': 'FF00FFFF',
                    'navigationbar_shadow#dark': '00FFFFFF',
                    'keyboard#light': 'FF00FFFF',
                    'keyboard#dark': '00FFFFFF',
                },
            },
            { label: 'Formal Language', key: 'formOfAddress', value: 'formal' },
            { label: 'Informal Language', key: 'formOfAddress', value: 'informal' },
        ],
    },
];
