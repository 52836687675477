import React, { FunctionComponent } from 'react';
import { HStack, Box, VStack, Text, Image } from '@chakra-ui/react';
import { Image as ImageInterface } from '../converter/Interface';
import { InvitationState, CustomerDataError, DashboardCustomerStats } from '../generated/types';
import { CBCustomerBadge } from './content_builder_components/CBCustomerBadge';
export interface ProfileCard {
    firstName: string;
    lastName?: string;
    email: string;
    profileImage?: ImageInterface;
    invitationState?: InvitationState;
    dataErrors?: CustomerDataError[];
    showState?: boolean;
    stats?: DashboardCustomerStats;
}

export const ProfileCard: FunctionComponent<ProfileCard> = (props) => {
    const { firstName, lastName, email, profileImage, invitationState, stats, dataErrors, showState = false } = props;

    return (
        <HStack>
            {profileImage && (
                <Box>
                    <Image
                        borderRadius="full"
                        boxSize={profileImage.boxSize || '48px'}
                        src={`data:${profileImage.type};base64,${profileImage.data}`}
                    />
                </Box>
            )}
            <VStack alignItems="left" spacing={0} pr="2">
                <Text>{[firstName, lastName].join(' ')} </Text>

                <Text variant="subText">
                    {showState === true ? (
                        <CBCustomerBadge invitationState={invitationState} dataErrors={dataErrors} stats={stats} />
                    ) : (
                        email
                    )}
                </Text>
            </VStack>
        </HStack>
    );
};
