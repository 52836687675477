import React, { FunctionComponent, ReactNode } from 'react';
import { Spacer, BoxProps, Flex, Box } from '@chakra-ui/react';

interface Props extends BoxProps {
    children: ReactNode | ReactNode[];
}

export const AlignBottom: FunctionComponent<Props> = (props) => {
    const { children, ...rest } = props;
    return (
        <Box>
            <Flex direction="column" height="100%" {...rest}>
                <Spacer />
                {children}
            </Flex>
        </Box>
    );
};
