import React, { FunctionComponent } from 'react';
import { Formik, Field } from 'formik';
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    VStack,
    Select,
    Textarea,
    HStack,
    Spacer,
} from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '../../components/Button';
import validator from 'validator';
import { Form } from '../../content_builder_inferface/form';
import { useNavigate } from 'react-router-dom';

interface Props {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    data: { [key: string]: any };
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    methods: { [key: string]: any };
    form: Form;
}

const uiElements = {
    Input,
    Select,
    Textarea,
};

export const CBForm: FunctionComponent<Props> = (props) => {
    const { form, data, methods } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();

    /** Initialize all field values by config and data. Else use empty string */
    const initialValues: { [key: string]: string } = {};
    form.fields.forEach((field) => {
        initialValues[field.name] = field.name in data ? data[field.name] : '';
    });

    /**
     * Define submit method for form
     * If defined method found in config call method with values
     * Else call console.log with values
     */

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const submit = (values: any) => {
        if (form.submitButton.onClick in methods) methods[form.submitButton.onClick](values);
        else console.log(values);
    };

    const cancel = () => {
        navigate(-1);
    };

    return (
        <Formik initialValues={initialValues} onSubmit={submit}>
            {({ handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit}>
                    <VStack spacing={4} align="flex-start">
                        {form.fields.map((field) => {
                            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                            const rest: any = {};
                            if (field.rows) rest['rows'] = field.rows;
                            return (
                                <FormControl
                                    key={field.name}
                                    isRequired={field.isRequired}
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    isInvalid={!!errors[field.name] && touched[field.name]}
                                >
                                    <FormLabel htmlFor={field.name}>
                                        <Trans>{field.name}</Trans>
                                    </FormLabel>
                                    <Field
                                        as={field.as ? uiElements[field.as] : uiElements['Input']}
                                        id={field.name}
                                        name={field.name}
                                        type={field.type ?? 'text'}
                                        placeholder={t(field.placeholder ?? '')}
                                        readOnly={field.isReadOnly}
                                        variant={field.isReadOnly ? 'outline' : 'filled'}
                                        validate={(value: string) => {
                                            let error;

                                            if (field.validate) {
                                                const key: string = field.validate.name;
                                                const options = field.validate.options;

                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                // @ts-ignore
                                                if (!validator[key](value, options)) {
                                                    const keyTranslation =
                                                        field.validate.errorMessage ?? 'invalidFieldValue';
                                                    error = t(keyTranslation);
                                                }
                                            }

                                            return error;
                                        }}
                                        {...rest}
                                    >
                                        {field.options?.map((option) => {
                                            return (
                                                <option value={option} key={option}>
                                                    <Trans>{option}</Trans>
                                                </option>
                                            );
                                        })}
                                    </Field>
                                    {/**  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                     * @ts-ignore */}
                                    <FormErrorMessage>{errors[field.name]}</FormErrorMessage>
                                </FormControl>
                            );
                        })}
                        <HStack width="100%">
                            <Spacer />
                            {form.additionalButtons.map((button) => {
                                let clickMethod = methods[button.onClick];
                                if (!clickMethod && button.onClick === 'cancel') clickMethod = cancel;
                                return (
                                    <Button key={button.label} variant="outline" onClick={clickMethod}>
                                        {button.label}
                                    </Button>
                                );
                            })}
                            {
                                <Button key={form.submitButton.label} variant="submit" type="submit">
                                    {form.submitButton.label}
                                </Button>
                            }
                        </HStack>
                    </VStack>
                </form>
            )}
        </Formik>
    );
};
