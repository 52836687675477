import moment from 'moment';

export enum Formatter {
    DATE = 'DATE',
}

export const formatter = (data: string | null, type: Formatter, format: string): string | null => {
    if (!data) return null;

    switch (type) {
        case Formatter.DATE:
            return moment(data).format(format);
        default:
            return data;
    }
};
