import React, { FunctionComponent, ReactNode } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { Text } from './Text';
import { CustomerMetaDataDetailsValueType } from '../generated/types';
import moment from 'moment';
import { Amount } from './Amount';

interface Props {
    label: string;
    value: string;
    valueType: CustomerMetaDataDetailsValueType;
    boxProps?: BoxProps;
}

export const MetaDataDetailsElement: FunctionComponent<Props> = (props: Props) => {
    const { label, value, valueType, boxProps } = props;
    const outputLabel = label.startsWith('category-') ? label.split('-')[0] : label;
    let output: ReactNode;
    switch (valueType) {
        case CustomerMetaDataDetailsValueType.Amount:
            const [amountString, currency] = value.split(' ');
            const amountNumber = parseFloat(amountString);
            output = <Amount amount={amountNumber} currency={currency} />;
            break;
        case CustomerMetaDataDetailsValueType.Date:
            output = <Text>{moment(value).format('DD.MM.YYYY HH:mm:ss')}</Text>;
            break;
        default:
            output = <Text>{value}</Text>;
    }
    return (
        <Box {...boxProps}>
            <Text variant="label">{outputLabel}</Text>
            {output}
        </Box>
    );
};
