export const heading = {
    baseStyle: {
        fontWeight: 400,
    },
    variants: {
        title: {
            fontSize: '3xl',
        },
        heading: {
            fontSize: '2xl',
        },
    },
};
