import React, { FunctionComponent, useEffect, useState } from 'react';
import { ContentBuilder } from '../../components/ContentBuilder';
import { ContentContainer } from '../../components/ContentContainer';
import LoadingIndicator from '../../components/LoadingIndicator';
import ErrorBox from '../../components/ErrorBox';
import { config } from '../../content_builder_config/communication/list';
import {
    CommunicationThreadWithCustomer,
    useDashboardCommunicationsForAdvisorQuery,
    useDashboardSetCommunicationReadingStateMutation,
} from '../../generated/types';
import { SearchSelectOption } from '../../components/table/Search';
import { MessagesOverlay, readingStateScopes } from '../../components/MessagesOverlay';
import { useDisclosure } from '@chakra-ui/react';
import { checkScope, getUser } from '../../keycloak';
import { useKeycloak } from '@react-keycloak/web';
import { isCodie } from '../../appBrand';

const infoUrl = isCodie
    ? 'https://hilfe.dashboard.app.fp-finanzpartner.de/sl-SI/kb/articles/wie-kann-ich-meine-kunden-in-der-fp-360-app-anschreiben'
    : undefined;

export const List: FunctionComponent = () => {
    const [resultCommunicationsQuery, reexecuteCommunicationsQuery] = useDashboardCommunicationsForAdvisorQuery();
    const { data, fetching, error } = resultCommunicationsQuery;
    const { keycloak } = useKeycloak();
    const user = getUser(keycloak);

    const [_state, setCommunicationReadingState] = useDashboardSetCommunicationReadingStateMutation();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [selectedThread, setSelectedThread] = useState<CommunicationThreadWithCustomer>();
    const [customerObject, setCustomerObject] = useState<{ id: string; name: string } | undefined>();
    const closeMessageWindow = () => {
        setSelectedThread(undefined);
        setCustomerObject(undefined);
        onClose();
    };

    const onPressMessage = async (data: CommunicationThreadWithCustomer): Promise<void> => {
        await setCustomerObject({ id: data.customer.foreignId, name: data.customer.fullName ?? '' });
        await setSelectedThread(data);
        await onOpen();
    };

    const searchFilterValues = (): SearchSelectOption[] => {
        return [
            { text: 'unread', value: 'false' },
            { text: 'read', value: 'true' },
        ];
    };

    const markThreadAsRead = async (threadId: string, isRead: boolean): Promise<void> => {
        if (!checkScope(user.roles, readingStateScopes)) return;

        const result = await setCommunicationReadingState({ threadId: threadId, isRead: isRead });
        if (selectedThread && result.data?.dashboardSetCommunicationReadingState === 'success') {
            const activeThread = data?.dashboardCommunicationsForAdvisor?.threads.find(
                (item) => item.communicationThreadId === threadId,
            );
            if (activeThread) {
                activeThread.advisorRead = isRead;
                selectedThread.advisorRead = isRead;
            }
        }
    };

    useEffect(() => {
        async function doMarkThreadAsRead() {
            if (selectedThread) {
                await markThreadAsRead(selectedThread?.communicationThreadId, true);
            }
        }

        doMarkThreadAsRead();
    }, [selectedThread]);

    if (fetching) return <LoadingIndicator />;
    if (error) return <ErrorBox message={error.message} />;

    return (
        <ContentContainer headline="messages" description="communicationDescription" infoUrl={infoUrl}>
            <ContentBuilder
                config={config.table}
                data={{ threads: data?.dashboardCommunicationsForAdvisor?.threads }}
                methods={{ searchFilterValues, onPressMessage, newMessage: onOpen }}
            />
            <MessagesOverlay
                isOpen={isOpen}
                selectedThread={selectedThread}
                closeMessageWindow={closeMessageWindow}
                advisorId={selectedThread?.customer.advisorId}
                customer={customerObject}
                markThreadAsRead={markThreadAsRead}
                reexecuteQuery={reexecuteCommunicationsQuery}
                customerSearchAble={true}
            />
        </ContentContainer>
    );
};
