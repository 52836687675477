import React, { FunctionComponent } from 'react';
import { FormattedText } from '../../content_builder_inferface/formattedText';
import { formatter } from '../../helper/formatter';
import { Text } from '../Text';

interface Props {
    data: string;
    formattedText: FormattedText;
}

export const CBFormattedText: FunctionComponent<Props> = (props) => {
    const { data, formattedText } = props;
    const { format } = formattedText;

    return <Text>{formatter(data, format.type, format.format)}</Text>;
};
