import { Config } from '../../content_builder_inferface';
import { TabElement } from '../../content_builder_inferface/tab';
import { Formatter } from '../../helper/formatter';

export const CustomerDetailsCodieTabs: TabElement[] = [
    {
        // https://beraterportal.app.fp-finanzpartner.de/beraterportal/VertragFormBT.aspx?id=
        // contracts: key 'contractIdentifier'
        // accounts: key 'accountNumber'
        name: 'codieContracts',
        component: [
            {
                as: 'CBTable',
                data: 'contracts',
                table: {
                    search: {
                        show: true,
                    },
                    header: {
                        amount: {
                            textAlign: 'right',
                        },
                    },
                    initialState: {
                        sortBy: [{ id: 'ProductName', desc: false }],
                    },
                    columns: [
                        { name: 'ContractNumber' },
                        { name: 'ProductName' },
                        { name: 'CompanyName' },
                        { name: 'InsuredPerson' },
                        { name: 'ContractHolderPerson' },
                        { name: 'StartDate' },
                        { name: 'EndDate' },
                        { name: 'recurrence' },
                        { name: 'amount' },
                    ],
                    defaultPageSize: 20,
                    isRowClickable: false,
                },
            },
        ],
    },
    {
        name: 'codieAssets',
        component: [
            {
                as: 'CBTable',
                data: 'accounts',
                table: {
                    search: {
                        show: true,
                    },
                    header: {
                        balance: {
                            textAlign: 'right',
                        },
                    },
                    defaultPageSize: 20,
                    isRowClickable: false,
                    initialState: {
                        sortBy: [{ id: 'productName', desc: false }],
                    },
                    columns: [
                        {
                            name: {
                                id: 'accountNumber',
                                label: 'ContractNumber',
                            },
                        },
                        { name: 'productName' },
                        { name: 'companyName' },
                        { name: 'balanceDate' },
                        { name: 'balance' },
                    ],
                },
            },
        ],
    },
];
